import React, { useCallback, useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useAuthentication } from "../common/contexts/authenticationContext";
import WrapperConnected from "../components/wrapper-conected";
import LoginPage from "./login/login";
import HomePage from "./dashboard/dashboard";
import AccountsList from "./accountsList/AccountsList";
// import Account from "./account/account";
import LostPassword from "./lostPassword/lostPassword";
import ResetPassword from "./resetPassword/resetPassword";
import PATH from "../constants/cts_routes";
import "remixicon/fonts/remixicon.css";
import { IUserAuthenticationInfos } from "../interfaces/user";
import Agents from "./agents/agents";
import Services from "./services/services";
import OrgChart from "./orgChart/newOrgChart";
import ArchivedAgents from "./agents/archivedAgents";
import AnonymisedAgents from "./agents/anonymisedAgents";
import ReferensJobs from "./referensJobs/referensJobs";
import Contact from "./contact/contact";
import Notifications from "./notifications/notifications";
import Notification from "./notifications/notification";
import { useNotifications } from "../common/contexts/notificationContext";
import { INotificationInfos } from "../interfaces/notification";
import CGV from "./cgv/cgv";
import { ROLE_ADMIN } from "../constants/cts_user";
import EditAgent from "./agents/editAgent";

// ⚠️ Lors de la création d'une nouvelle route, il faut ajouter le chemin dans le
// fichier "front/src/constants/cts_routes.tsx"

const AppRoutes = () => {
  const initialAuthenticatedUserInfos = {
    id: -1,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    image: "",
    roleName: "",
    password: "",
    created_at: "",
    isWaitingAuthenticationResponse: true,
    isAuthenticated: false,
  };
  const { onGetUserIfIsAuthenticated, user } = useAuthentication();
  const [checkUser, _setCheckUser] = useState<IUserAuthenticationInfos>(
    initialAuthenticatedUserInfos
  );
  const { onGetAllNotifications } = useNotifications();
  const [notifications, _setNotifications] = useState<INotificationInfos[]>([]);

  // set user authenticated infos
  useEffect(() => {
    onGetUserIfIsAuthenticated()
      .then((returnUser) => {
        if (returnUser) {
          // authenticated user
          _setCheckUser({
            ...returnUser,
            isWaitingAuthenticationResponse: false,
            isAuthenticated: true,
          });
        } else {
          // not authenticated user
          _setCheckUser({
            ...initialAuthenticatedUserInfos,
            isWaitingAuthenticationResponse: false,
            isAuthenticated: false,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [user]);

  // on logout / user does not exist -> set the default initial settings to prevent bugs (page flickering)
  useEffect(() => {
    if (!user) {
      _setCheckUser({
        ...initialAuthenticatedUserInfos,
        isWaitingAuthenticationResponse: true,
        isAuthenticated: false,
      });
    }
  }, [user]);

  // GET ALL NOTIFICATIONS
  const loadNotifications = useCallback(() => {
    onGetAllNotifications()
      .then((response) => _setNotifications(response))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (checkUser.isAuthenticated) {
      loadNotifications();
    }
  }, [checkUser]);

  return checkUser.isWaitingAuthenticationResponse ? (
    <></>
  ) : (
    <Routes>
      <Route element={<NotAuthenticatedRoutes checkUser={checkUser} />}>
        <Route path={PATH.login} element={<LoginPage />} />
        <Route path={PATH.lost_pwd} element={<LostPassword />} />
        <Route path={PATH.reset_pwd} element={<ResetPassword />} />
        <Route path={PATH.cgv} element={<CGV />} />
      </Route>
      <Route
        element={<ProtectedRoutes path={PATH.login} checkUser={checkUser} />}
      >
        <Route
          index
          path={PATH.home}
          element={
            <WrapperConnected notifications={notifications}>
              <HomePage />
            </WrapperConnected>
          }
        />

        <Route
          index
          path={PATH.editAgent}
          element={
            <WrapperConnected notifications={notifications}>
              <EditAgent />
            </WrapperConnected>
          }
        />

        {user && user.role === ROLE_ADMIN && (
          <>
            <Route
              path={PATH.users}
              element={
                <WrapperConnected notifications={notifications}>
                  <AccountsList />
                </WrapperConnected>
              }
            />
          </>
        )}

        <Route
          path={PATH.anonymisedAgents}
          element={
            <WrapperConnected notifications={notifications}>
              <AnonymisedAgents />
            </WrapperConnected>
          }
        />

        <Route
          index
          path={PATH.referensJobs}
          element={
            <WrapperConnected notifications={notifications}>
              <ReferensJobs />
            </WrapperConnected>
          }
        />

        <Route
          path={PATH.archivedAgents}
          element={
            <WrapperConnected notifications={notifications}>
              <ArchivedAgents />
            </WrapperConnected>
          }
        />
        <Route
          path={PATH.agents}
          element={
            <WrapperConnected notifications={notifications}>
              <Agents />
            </WrapperConnected>
          }
        />

        <Route
          path={PATH.services}
          element={
            <WrapperConnected notifications={notifications}>
              <Services />
            </WrapperConnected>
          }
        />
        <Route
          path={PATH.organizationalChart}
          element={
            <WrapperConnected notifications={notifications}>
              <OrgChart />
            </WrapperConnected>
          }
        />
        <Route
          path={PATH.contact}
          element={
            <WrapperConnected notifications={notifications}>
              <Contact />
            </WrapperConnected>
          }
        />
        <Route
          path={PATH.notifications}
          element={
            <WrapperConnected notifications={notifications}>
              <Notifications notifications={notifications} />
            </WrapperConnected>
          }
        />
        <Route
          path={`${PATH.notification}/:id`}
          element={
            <WrapperConnected notifications={notifications}>
              <Notification loadNotifications={loadNotifications} />
            </WrapperConnected>
          }
        />
        {/* <Route
          path={PATH.account}
          element={
            <WrapperConnected>
              <Account />
            </WrapperConnected>
          }
        /> */}
      </Route>
      <Route
        path="/*"
        element={<RedirectToIndexPage checkUser={checkUser} />}
      />
    </Routes>
  );
};

const NotAuthenticatedRoutes = ({
  checkUser,
}: {
  checkUser: IUserAuthenticationInfos;
}) => {
  if (checkUser.isAuthenticated) {
    return <Navigate to={PATH.home} replace />;
  } else {
    return <Outlet />;
  }
};

const ProtectedRoutes = ({
  path,
  checkUser,
}: {
  path: string;
  checkUser: IUserAuthenticationInfos;
}) => {
  if (checkUser.isAuthenticated) {
    return <Outlet />;
  } else {
    return <Navigate to={path} replace />;
  }
};

const RedirectToIndexPage = ({
  checkUser,
}: {
  checkUser: IUserAuthenticationInfos;
}) => {
  return <Navigate to={checkUser.isAuthenticated ? PATH.home : PATH.login} />;
};

export default AppRoutes;
