import {
  IAgentAddEditDivisionFormValues,
  ITeleworking,
} from "../interfaces/agent";
import { instanceAxios } from "../utils/axios-api";

// CREATE ONE
export const onCreateAgentApi = async ({
  firstName,
  lastName,
  sex,
  birthDate,
  registrationNumber,
  maritalStatus,
  professionalPhoneNumber,
  email,
  observationsDifficultJobs,
  observationsParticularSituations,
  bonusVacation,
  departureDate,
  departureReason,
  categorieAgent,
  corpsAgent,
  statut,
  typeContrat,
  date1erCDD,
  dateCDDactuel,
  dateFinCDDactuel,
  emploiType,
  obtentionLATA,
  agentTT,
  BAPPosteOccupe,
  catPostOccupe,
  quotite,
  oldRegistrationNumber,
  mobilePhoneNumber,
  aideRenommage,
  loginGABS,
  dernierDiplomeObtenu,
  nomDernierDiplomeObtenu,
  tempsTravailHebdomataire,
  dacAnnuelReglementaire,
}: {
  firstName: string | null;
  lastName: string | null;
  sex: string | null;
  birthDate: Date | null;
  registrationNumber: string | null;
  maritalStatus: string | null;
  professionalPhoneNumber: string | null;
  observationsDifficultJobs: string | null;
  observationsParticularSituations: string | null;
  email: string | null;
  bonusVacation: string | null;
  departureDate: Date | null;
  departureReason: string | null;
  categorieAgent: string | null;
  corpsAgent: string | null;
  statut: string | null;
  typeContrat: string | null;
  date1erCDD: string | null;
  dateCDDactuel: string | null;
  dateFinCDDactuel: string | null;
  emploiType: string | null;
  obtentionLATA: string | null;
  agentTT: string | null;
  BAPPosteOccupe: string | null;
  catPostOccupe: string | null;
  quotite: string | null;
  oldRegistrationNumber: string | null;
  mobilePhoneNumber: string | null;
  aideRenommage: string | null;
  loginGABS: string | null;
  dernierDiplomeObtenu: string | null;
  nomDernierDiplomeObtenu: string | null;
  tempsTravailHebdomataire: string | null;
  dacAnnuelReglementaire: string | null;
}) => {
  return instanceAxios
    .post("/agents/create-agent", {
      firstName,
      lastName,
      sex,
      birthDate,
      registrationNumber,
      maritalStatus,
      professionalPhoneNumber,
      email,
      observationsDifficultJobs,
      observationsParticularSituations,
      bonusVacation,
      departureDate,
      departureReason,
      categorieAgent,
      corpsAgent,
      statut,
      typeContrat,
      date1erCDD,
      dateCDDactuel,
      dateFinCDDactuel,
      emploiType,
      obtentionLATA,
      agentTT,
      BAPPosteOccupe,
      catPostOccupe,
      quotite,
      oldRegistrationNumber,
      mobilePhoneNumber,
      aideRenommage,
      loginGABS,
      dernierDiplomeObtenu,
      nomDernierDiplomeObtenu,
      tempsTravailHebdomataire,
      dacAnnuelReglementaire,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// GET ALL
export const onGetAllAgentsApi = async () => {
  return instanceAxios
    .get(`/agents/get-all-agents`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL ARCHIVED
export const onGetAllArchivedAgentsApi = async () => {
  return instanceAxios
    .get(`/agents/get-all-archived`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ALL ANONYMISED
export const onGetAllAnonymisedAgentsApi = async () => {
  return instanceAxios
    .get(`/agents/get-all-anonymised`)
    .then((response) => (response.data ? response.data.data : null));
};

// RESTORE AGENT FROM ARCHIVED AGENTS
export const onRestoreAgentApi = async (id: number) => {
  return instanceAxios
    .put(`/agents/restore-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// GET ONE
export const onGetAgentApi = async (id: number) => {
  return instanceAxios
    .get(`/agents/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE DIVISION AGENT
export const onUpdateAgentDivisionApi = async ({
  id,
  divisionId,
}: {
  id: number;
  divisionId: number;
}) => {
  return instanceAxios
    .put("/agents/update-division-agent/" + id, {
      divisionId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// CHECK IF MATRICULE EXISTS
export const onCheckRegistrationNumberOfAgentApi = async (
  registrationNumber: number
) => {
  return instanceAxios
    .get(`/agents/check-registration-number-agent/${registrationNumber}`)
    .then((response) => (response.data ? response.data.data : null));
};

// DELETE ONE
export const onDeleteAgentApi = async (id: number) => {
  return instanceAxios
    .delete(`/agents/delete-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// ANONYM ONE
export const onAnonymAgentApi = async (id: number) => {
  return instanceAxios
    .put(`/agents/anonym-agent/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

// delete selected tags
export const onDeleteAllFromIdListApi = async (agentIds: Array<number>) => {
  return instanceAxios
    .delete("/agents/delete-all-from-id-list/", { params: { ids: agentIds } })
    .then((response) => (response.data ? response.data.data : null));
};

// UPDATE ONE
export const onUpdateAgentApi = async ({
  id,
  firstName,
  lastName,
  sex,
  birthDate,
  registrationNumber,
  maritalStatus,
  professionalPhoneNumber,
  email,
  observationsDifficultJobs,
  observationsParticularSituations,
  bonusVacation,
  departureDate,
  departureReason,
  categorieAgent,
  corpsAgent,
  statut,
  typeContrat,
  date1erCDD,
  dateCDDactuel,
  dateFinCDDactuel,
  emploiType,
  obtentionLATA,
  agentTT,
  BAPPosteOccupe,
  catPostOccupe,
  quotite,
  oldRegistrationNumber,
  mobilePhoneNumber,
  aideRenommage,
  loginGABS,
  dernierDiplomeObtenu,
  nomDernierDiplomeObtenu,
  tempsTravailHebdomataire,
  dacAnnuelReglementaire,
}: {
  id: number;
  firstName: string | null;
  lastName: string | null;
  sex: string | null;
  birthDate: Date | null;
  registrationNumber: string | null;
  maritalStatus: string | null;
  professionalPhoneNumber: string | null;
  observationsDifficultJobs: string | null;
  observationsParticularSituations: string | null;
  email: string | null;
  bonusVacation: string | null;
  departureDate: Date | null;
  departureReason: string | null;
  categorieAgent: string | null;
  corpsAgent: string | null;
  statut: string | null;
  typeContrat: string | null;
  date1erCDD: string | null;
  dateCDDactuel: string | null;
  dateFinCDDactuel: string | null;
  emploiType: string | null;
  obtentionLATA: string | null;
  agentTT: string | null;
  BAPPosteOccupe: string | null;
  catPostOccupe: string | null;
  quotite: string | null;
  oldRegistrationNumber: string | null;
  mobilePhoneNumber: string | null;
  aideRenommage: string | null;
  loginGABS: string | null;
  dernierDiplomeObtenu: string | null;
  nomDernierDiplomeObtenu: string | null;
  tempsTravailHebdomataire: string | null;
  dacAnnuelReglementaire: string | null;
}) => {
  return instanceAxios
    .put("/agents/update-agent/" + id, {
      firstName,
      lastName,
      sex,
      birthDate,
      registrationNumber,
      maritalStatus,
      professionalPhoneNumber,
      email,
      observationsDifficultJobs,
      observationsParticularSituations,
      bonusVacation,
      departureDate,
      departureReason,
      categorieAgent,
      corpsAgent,
      statut,
      typeContrat,
      date1erCDD,
      dateCDDactuel,
      dateFinCDDactuel,
      emploiType,
      obtentionLATA,
      agentTT,
      BAPPosteOccupe,
      catPostOccupe,
      quotite,
      oldRegistrationNumber,
      mobilePhoneNumber,
      aideRenommage,
      loginGABS,
      dernierDiplomeObtenu,
      nomDernierDiplomeObtenu,
      tempsTravailHebdomataire,
      dacAnnuelReglementaire,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

export const onUpdateAgentOnlyApi = async ({
  id,
  firstName,
  lastName,
  sex,
  birthDate,
  teleworking,
  registrationNumber,
  observationsDifficultJobs,
  observationsParticularSituations,
  professionalPhoneNumber,
  email,
  bonusVacation,
  departureDate,
  departureReason,
  category,
  corps,
  obtentionLATA,
  formations,
  employments,
  oldRegistrationNumber,
  mobilePhoneNumber,
  aideRenommage,
  loginGABS,
  dernierDiplomeObtenu,
  nomDernierDiplomeObtenu,
  tempsTravailHebdomataire,
  dacAnnuelReglementaire,
}: {
  id: number;
  firstName: string | null;
  lastName: string | null;
  sex: string | null;
  birthDate: Date | null;
  teleworking: ITeleworking | null;
  registrationNumber: string | null;
  observationsDifficultJobs: string | null;
  observationsParticularSituations: string | null;
  professionalPhoneNumber: string | null;
  email: string | null;
  bonusVacation: string | null;
  departureDate: Date | null;
  departureReason: string | null;
  category: string | null;
  corps: string | null;
  obtentionLATA: string | null;
  formations: any[];
  employments: any[];
  oldRegistrationNumber: string | null;
  mobilePhoneNumber: string | null;
  aideRenommage: string | null;
  loginGABS: string | null;
  dernierDiplomeObtenu: string | null;
  nomDernierDiplomeObtenu: string | null;
  tempsTravailHebdomataire: string | null;
  dacAnnuelReglementaire: string | null;
}) => {
  // console.log("UPDATE AGENT ONLY API : ", formations, employments);

  return instanceAxios
    .put("/agents/update-agent-only/" + id, {
      firstName,
      lastName,
      sex,
      birthDate,
      teleworking: teleworking ? teleworking.days : null,
      registrationNumber,
      observationsDifficultJobs,
      observationsParticularSituations,
      professionalPhoneNumber,
      email,
      bonusVacation,
      departureDate,
      departureReason,
      category,
      corps,
      obtentionLATA,
      formations: JSON.stringify(formations),
      employments: JSON.stringify(employments),
      oldRegistrationNumber,
      mobilePhoneNumber,
      aideRenommage,
      loginGABS,
      dernierDiplomeObtenu,
      nomDernierDiplomeObtenu,
      tempsTravailHebdomataire,
      dacAnnuelReglementaire,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

// ADD / EDIT AGENT DIVISION
export const onAddEditAgentDivisionApi = async ({
  agentId,
  divisionId,
}: IAgentAddEditDivisionFormValues) => {
  return instanceAxios
    .put("/agents/add-edit-agent-division/" + agentId, {
      divisionId,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch((error) => {
      throw error.response ? error.response.data : error.message;
    });
};

export const onGetAgentDetailsApi = async (id: number) => {
  return instanceAxios
    .get(`/agents/get-agent-details/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};
