import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import {
  COLOR_BTN,
  COLOR_BTN_HOVER,
  COLOR_TEXT_MUTED,
  COLOR_WHITE,
} from "../constants/cts_colors";

const Button = ({
  text,
  onClick,
  disabled,
}: {
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}) => {
  return (
    <ButtonStyles
      disabled={disabled}
      type="button"
      onClick={onClick}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        backgroundColor: disabled ? COLOR_TEXT_MUTED : COLOR_BTN,
      }}
    >
      {text}
    </ButtonStyles>
  );
};

export default Button;

const ButtonStyles = styled.button`
  display: block;
  width: max-content;
  background-color: ${COLOR_BTN};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: ${COLOR_WHITE};
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8rem;
  transition: background-color 250ms ease-in;

  // ========= MEDIA QUERIES - Form / FORM_SUBMIT_BTN ============
  @media (max-width: 800px) {
    font-size: 3vw;
  }

  &:hover {
    background-color: ${COLOR_BTN_HOVER};
  }
`;
