import styled from "styled-components";
import {
  COLOR_BACKGROUND,
  COLOR_BLACK,
  COLOR_BTN,
  COLOR_BTN_HOVER,
  COLOR_TITLE,
} from "../../../constants/cts_colors";
import { IReferensJobInfos } from "../../../interfaces/referensJob";
import {
  MdKeyboardDoubleArrowRight,
  MdOutlineDescription,
} from "react-icons/md";
import { IoIosDownload } from "react-icons/io";

const ReferensJobViewer = ({
  contentJob,
  contentKey,
  selectedId,
  onDisplay,
  isAdmin,
  onEditReferensJob,
}: {
  contentJob: IReferensJobInfos;
  contentKey: number;
  selectedId: number;
  onDisplay: Function;
  isAdmin: boolean;
  onEditReferensJob: Function;
}) => {
  // console.log("CONTENT : ", contentJobs);
  let contentJobActivities = [""];
  if (contentJob.mainActivities)
    contentJobActivities = contentJob.mainActivities.split("|");
  let contentJobKnowledges = [""];
  if (contentJob.knowledge)
    contentJobKnowledges = contentJob.knowledge.split("|");
  let contentJobOperationalSkills = [""];
  if (contentJob.operationalSkills)
    contentJobOperationalSkills = contentJob.operationalSkills.split("|");
  let contentJobBehavioralSkills = [""];
  if (contentJob.behavioralSkills)
    contentJobBehavioralSkills = contentJob.behavioralSkills.split("|");
  let contentJobStatutoryDiplomaRequired = [""];
  if (contentJob.statutoryDiplomaRequired)
    contentJobStatutoryDiplomaRequired =
      contentJob.statutoryDiplomaRequired.split("|");
  let contentJobImpactOnJob = [""];
  if (contentJob.impactOnJob)
    contentJobImpactOnJob = contentJob.impactOnJob.split("|");
  let contentJobEvolutionFactors = [""];
  if (contentJob.mediumtermDevelopmentFactors)
    contentJobEvolutionFactors =
      contentJob.mediumtermDevelopmentFactors.split("|");

  return (
    <ReferensJobViewerStyles key={contentKey}>
      <div className="head">
        <MdKeyboardDoubleArrowRight
          onClick={() => {
            onDisplay();
          }}
          className={selectedId === contentKey ? "active icon" : "icon"}
          size={24}
        />
        <h5
          onClick={() => {
            onDisplay();
          }}
        >
          {contentJob.codeJob} - {contentJob.jobTitle}
        </h5>
        <a
          target="_blank"
          href={contentJob.pdfFile}
          style={{
            color: COLOR_TITLE,
            marginLeft: "8px",
            position: "relative",
            top: "2px",
          }}
        >
          <IoIosDownload />
        </a>
      </div>
      <div className={selectedId === contentKey ? "active content" : "content"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h5>
            {contentJob.bap} - {contentJob.professionalFamily} -{" "}
            {contentJob.codeStatutoryCorrespondence}
          </h5>
          {isAdmin && (
            <EditJobButton
              onClick={() => {
                onEditReferensJob();
              }}
            >
              <MdOutlineDescription size={20} />
            </EditJobButton>
          )}
        </div>
        <h3>
          {contentJob.codeJob} - {contentJob.jobTitle}
        </h3>
        <div>
          <TableReferens>
            <div>
              <b>Mission</b>
            </div>
            <p>{contentJob.mission}</p>
          </TableReferens>
          {/* Famille d’activité professionnelle & Correspondance statutaire  */}
          {/* <DivivedTableReferens> */}
          <TableReferens>
            <div>
              <b>Famille d’activité professionnelle</b>
            </div>
            <p style={{ textAlign: "center" }}>
              {contentJob.professionalFamily}
            </p>
          </TableReferens>
          <TableReferens>
            <div>
              <b>Correspondance statutaire</b>
            </div>
            <p style={{ textAlign: "center" }}>
              {contentJob.statutoryCorrespondence}
            </p>
          </TableReferens>
          {/* </DivivedTableReferens> */}
          {/* REME */}
          {/* <DivivedTableReferens> */}
          <TableReferens>
            <div>
              <b>
                Famille d’activité professionnelle
                <br /> REME
              </b>
            </div>
            <p style={{ textAlign: "center" }}>{contentJob.fapReme}</p>
          </TableReferens>
          {/* Emploi-type de rattachement REME */}
          <TableReferens>
            <div>
              <b>Emploi-type de rattachement REME</b>
            </div>
            <p style={{ textAlign: "center" }}>{contentJob.reme}</p>
          </TableReferens>
          {/* </DivivedTableReferens> */}
          {/* MAIN ACTIVITIES */}
          <TableReferens>
            <div>
              <b>Activités principales</b>
            </div>
            <ul>
              {contentJobActivities &&
                contentJobActivities.map(
                  (contentJobActivity, contentJobActivityKey) => {
                    return (
                      <li key={contentJobActivityKey}>{contentJobActivity}</li>
                    );
                  }
                )}
            </ul>
          </TableReferens>
          {/* PRINCIPALES COMPETENCES */}
          <TableReferens>
            <div>
              <b>Compétences principales</b>
            </div>
          </TableReferens>
          {/* CONNAISSANCES */}
          <TableReferens>
            <div>
              <b>Connaissances</b>
            </div>
            <ul>
              {contentJobKnowledges &&
                contentJobKnowledges.map(
                  (contentJobKnowledge, contentJobKnowledgeKey) => {
                    return (
                      <li key={contentJobKnowledgeKey}>
                        {contentJobKnowledge}
                      </li>
                    );
                  }
                )}
            </ul>
          </TableReferens>
          {/* Compétences opérationnelles */}
          <TableReferens>
            <div>
              <b>Compétences opérationnelles</b>
            </div>
            <ul>
              {contentJobOperationalSkills &&
                contentJobOperationalSkills.map(
                  (
                    contentJobOperationalSkill,
                    contentJobOperationalSkillKey
                  ) => {
                    return (
                      <li key={contentJobOperationalSkillKey}>
                        {contentJobOperationalSkill}
                      </li>
                    );
                  }
                )}
            </ul>
          </TableReferens>
          {/* Compétences comportementales */}
          <TableReferens>
            <div>
              <b>Compétences comportementales</b>
            </div>
            <ul>
              {contentJobBehavioralSkills &&
                contentJobBehavioralSkills.map(
                  (contentJobBehavioralSkill, contentJobBehavioralSkillKey) => {
                    return (
                      <li key={contentJobBehavioralSkillKey}>
                        {contentJobBehavioralSkill}
                      </li>
                    );
                  }
                )}
            </ul>
          </TableReferens>
          {/* Diplôme réglementaire exigé - Formation professionnelle si souhaitable */}
          <TableReferens>
            <div>
              <b>
                Diplôme réglementaire exigé - Formation professionnelle si
                souhaitable
              </b>
            </div>
            <ul>
              {contentJobStatutoryDiplomaRequired &&
                contentJobStatutoryDiplomaRequired.map(
                  (
                    contentJobStatutoryDiplomaRequiredLi,
                    contentJobStatutoryDiplomaRequiredKey
                  ) => {
                    return (
                      <li key={contentJobStatutoryDiplomaRequiredKey}>
                        {contentJobStatutoryDiplomaRequiredLi}
                      </li>
                    );
                  }
                )}
            </ul>
          </TableReferens>
          {/* TENDANCES D'ÉVOLUTION */}
          <TableReferens>
            <div>
              <b>Tendances d’évolution</b>
            </div>
          </TableReferens>
          {/* Diplôme réglementaire exigé - Formation professionnelle si souhaitable */}
          <TableReferens>
            <div>
              <b>Facteurs d’évolution à moyen terme</b>
            </div>
            <ul>
              {contentJobEvolutionFactors &&
                contentJobEvolutionFactors.length !== 0 &&
                contentJobEvolutionFactors.map(
                  (
                    contentJobEvolutionFactorLi,
                    contentJobEvolutionFactorKey
                  ) => {
                    return (
                      <li key={contentJobEvolutionFactorKey}>
                        {contentJobEvolutionFactorLi}
                      </li>
                    );
                  }
                )}
            </ul>
          </TableReferens>
          {/* Impacts sur l’emploi-type (qualitatif) */}
          <TableReferens>
            <div>
              <b>Impacts sur l’emploi-type (qualitatif)</b>
            </div>
            <ul>
              {contentJobImpactOnJob &&
                contentJobImpactOnJob.length !== 0 &&
                contentJobImpactOnJob.map(
                  (contentJobImpactOnJobLi, contentJobImpactOnJobKey) => {
                    return (
                      <li key={contentJobImpactOnJobKey}>
                        {contentJobImpactOnJobLi}
                      </li>
                    );
                  }
                )}
            </ul>
          </TableReferens>
          {/* Ancien code de l’emploi-type REFERENS */}
          <TableReferens>
            <div>
              <b>Ancien code de l’emploi-type REFERENS</b>
            </div>
            <p style={{ textAlign: "center" }}>{contentJob.previousCodeJob}</p>
          </TableReferens>
          {/* Ancien code de l’emploi-type REFERENS */}
          <TableReferens>
            <div>
              <b>Ancien intitulé de l’emploi-type REFERENS</b>
            </div>
            <p style={{ textAlign: "center" }}>{contentJob.previousJobTitle}</p>
          </TableReferens>
        </div>

        {/* IF COMPETITION*/}
        {contentJob.isExternalCompetition && (
          <CompetitionDiv>
            {contentJob.externalCompetitionProgram && (
              <div>
                <p>Programme de concours externe : </p>
                <a target="_blank" href={contentJob.externalCompetitionProgram}>
                  Voir
                </a>
              </div>
            )}
            {contentJob.externalCompetitionProgramNormal && (
              <div>
                <p>Programme de concours externe (Classe Normale) : </p>
                <a
                  target="_blank"
                  href={contentJob.externalCompetitionProgramNormal}
                >
                  Voir
                </a>
              </div>
            )}
            {contentJob.externalCompetitionProgramSuperior && (
              <div>
                <p>Programme de concours externe (Classe Supérieure) : </p>
                <a
                  target="_blank"
                  href={contentJob.externalCompetitionProgramSuperior}
                >
                  Voir
                </a>
              </div>
            )}
          </CompetitionDiv>
        )}

        {/* FICHE AU FORMAT PDF */}
        <CompetitionDiv>
          <div>
            <p>Fiche au format PDF : </p>
            <a target="_blank" href={contentJob.pdfFile}>
              Voir
            </a>
          </div>
        </CompetitionDiv>
      </div>
    </ReferensJobViewerStyles>
  );
};

export default ReferensJobViewer;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const ReferensJobViewerStyles = styled.div`
  font-size: 1.3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.25 ease-out;

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${COLOR_TITLE};
    cursor: pointer;

    :hover {
      color: ${COLOR_BTN};
    }

    & > .icon {
      transform: rotateZ(0deg);

      &.active {
        transform: rotateZ(90deg);
      }
    }
  }

  .content {
    display: none;
    margin: 5px;
    padding: 20px 10px;
    background-color: ${COLOR_BACKGROUND};
    border-radius: 10px;

    &.active {
      display: flex;
      gap: 3px;
      flex-direction: column;
    }
  }
`;

const TableReferens = styled.div`
  div {
    text-align: center;
    background-color: rgba(252, 97, 97, 0.95);
    border: 2px solid black;
    padding: 2px;
    color: ${COLOR_BACKGROUND};
  }

  p {
    padding: 4px 8px;
    text-align: left;
    border: 2px solid black;
    border-top: 0;
  }

  ul {
    padding: 4px 32px;
    border: 2px solid black;
    border-top: 0;
  }

  &.left {
    border-right: 0;
  }

  &.right {
  }
`;

const EditJobButton = styled.div`
  color: ${COLOR_TITLE};
  cursor: pointer;

  :hover {
    color: ${COLOR_BTN_HOVER};
  }
`;

const CompetitionDiv = styled.div`
  padding: 4px 0px;
  font-size: 0.9em;
  div {
    display: flex;
    gap: 4px;
    margin: 8px 0px;

    a {
      color: ${COLOR_TITLE};
      text-decoration: none;
    }
  }
`;
