import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import FormBase from "../../../components/form/FormBase";
import Button from "../../../components/Button";
import { useDivisions } from "../../../common/contexts/divisionContext";
import FormLabel from "../../../components/form/FormLabel";
import FormInputContainer from "../../../components/form/FormInputContainer";
import FormErrorMessageContainer from "../../../components/form/FormErrorMessageContainer";
import { IDivisionInfos } from "../../../interfaces/division";
import {
  COLOR_RED_BRIGHT,
  COLOR_TEXT,
  COLOR_TITLE,
} from "../../../constants/cts_colors";
import { IResponsibleFormValues } from "../../../interfaces/responsible";
import { useAgents } from "../../../common/contexts/agentContext";
import { IAgentFormValues } from "../../../interfaces/agent";
import { useResponsibles } from "../../../common/contexts/responsibleContext";
import { BiRename } from "react-icons/bi";
import {
  RESPONSIBLE_ASSISTANT_TYPE,
  RESPONSIBLE_DIRECTOR_ASSISTANT_TYPE,
  RESPONSIBLE_DIRECTOR_TYPE,
  RESPONSIBLE_TYPE,
} from "../../../constants/cts_responsibles";
import ToastAlert from "../../../components/ToastAlert";
import Modal from "../../../components/Modal";

const REPONSABLE_TYPES = [
  RESPONSIBLE_DIRECTOR_TYPE,
  RESPONSIBLE_DIRECTOR_ASSISTANT_TYPE,
  RESPONSIBLE_TYPE,
  RESPONSIBLE_ASSISTANT_TYPE,
];

const EditDivisionForm = ({
  title,
  formData,
  actionString,
  onDivisionUpdated,
  division,
}: {
  title: string;
  division?: IDivisionInfos;
  formData: IDivisionInfos;
  actionString: string;
  onDivisionUpdated: Function;
}) => {
  const initialFormResponsableState = {
    agentId: null,
    divisionId: null,
    responsibleType: null,
  };

  const [formValues, _setFormValues] = useState<IDivisionInfos>(formData);
  const [formValuesResponsable, _setFormValuesResponsable] =
    useState<IResponsibleFormValues>(initialFormResponsableState);
  const [errorMessage, _setErrorMessage] = useState<string | null>(null);
  const { onUpdateDivision } = useDivisions();
  const [responsibles, _setResponsibles] = useState<
    Array<IResponsibleFormValues>
  >([]);
  const { onGetAllAgents } = useAgents();
  const [agents, _setAgents] = useState<Array<IAgentFormValues>>([]);
  const {
    onCreateResponsible,
    onGetAllResponsiblesFromDivision,
    onDeleteResponsible,
  } = useResponsibles();
  const [confirmModal, _setConfirmModal] = useState<{
    message: string;
    confirmedAction: Function;
    params?: Object;
  } | null>(null);
  const [toastMessage, _setToastMessage] = useState<string>("");
  const [toastVisible, _setToastVisible] = useState<boolean>(false);

  // on submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (division) {
      for (let i = 0; i < responsibles.length; i++) {
        const resp = responsibles[i];
        if (!resp.id) {
          await onCreateResponsible({
            agentId: resp.agentId,
            divisionId: division.id,
            responsibleType: resp.responsibleType,
          });
        } else if (resp.id && resp.deleted) {
          await onDeleteResponsible(resp.id);
        }
      }
      _setFormValuesResponsable(initialFormResponsableState);
    }

    onUpdateDivision({
      ...formValues,
    })
      .then(() => {
        onDivisionUpdated();
      })
      .catch((err) => {
        _setErrorMessage(err);
      });
  };

  // on change
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    _setFormValues({
      ...formValues,
      [name]: value ? value : null,
    });
  };

  // on change
  const handleChangResponsable = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    _setFormValuesResponsable({
      ...formValuesResponsable,
      [name]: value ? value : null,
    });
  };

  const onAddResponsable = () => {
    if (
      !formValuesResponsable.agentId ||
      !formValuesResponsable.responsibleType
    ) {
      alert("Vous devez saisir un agent et un rôle");
      return;
    }

    responsibles.push({
      id: undefined,
      agentId: +formValuesResponsable.agentId,
      divisionId: division?.id || null,
      responsibleType: formValuesResponsable.responsibleType,
      agent: agents.find(
        (agent) => agent.id === +(formValuesResponsable.agentId || 0)
      ),
    });
    _setResponsibles([...responsibles]);
  };

  // on delete
  const handleDelete = (responsibleId: number) => {
    const findIndex = responsibles.findIndex(
      (responsible) => responsible.id === responsibleId
    );
    if (findIndex !== -1) {
      responsibles[findIndex].deleted = true;
      _setResponsibles([...responsibles]);
    }
  };

  useEffect(() => {
    onGetAllAgents().then((response) => {
      _setAgents(response);
    });

    if (division) {
      onGetAllResponsiblesFromDivision(division.id).then((response: any) => {
        _setResponsibles(response);
      });
    }
  }, []);

  return (
    <FormBase title={title}>
      <FormContainer>
        <FormScrollable>
          <Form onSubmit={handleSubmit}>
            <div className="inputs-container">
              <div className="grid-double">
                {/* name */}
                <div>
                  <FormLabel htmlFor="name" text="Nom Service" />
                  <FormInputContainer
                    input={
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Nom Service"
                        onChange={handleChange}
                        value={formValues.name ? formValues.name : ""}
                      />
                    }
                  />
                </div>

                {/* acronym */}
                <div>
                  <FormLabel htmlFor="acronym" text="Acronyme Service" />
                  <FormInputContainer
                    input={
                      <input
                        type="text"
                        id="acronym"
                        name="acronym"
                        placeholder="Acronyme Service"
                        onChange={handleChange}
                        value={formValues.acronym ? formValues.acronym : ""}
                      />
                    }
                  />
                </div>
              </div>

              <div className="inputs-container">
                <HistoriqueResponsibles>
                  <h4>- Responsables -</h4>
                  {responsibles && responsibles.length > 0 && (
                    <div className="actual-responsibles">
                      {responsibles
                        .filter((r) => !r.deleted)
                        .map((responsible: any, key) => {
                          return (
                            <ResponsibleCard key={key}>
                              <EditDeleteIcons
                                style={{ left: 0 }}
                                className="ri-delete-bin-line"
                                onClick={() => {
                                  if (responsible.id)
                                    handleDelete(responsible.id);
                                }}
                              ></EditDeleteIcons>

                              <h4>
                                {responsible.agent.firstName}{" "}
                                {responsible.agent.lastName}
                              </h4>
                              <p>-</p>
                              <h4>{responsible.responsibleType}</h4>
                            </ResponsibleCard>
                          );
                        })}
                    </div>
                  )}
                  {!responsibles ||
                    (responsibles.length < 1 && (
                      <p
                        style={{
                          textAlign: "center",
                          color: COLOR_TEXT,
                          paddingTop: 12,
                        }}
                      >
                        Aucun responsable pour le moment.
                      </p>
                    ))}
                </HistoriqueResponsibles>

                <ResponsableLine>
                  {/* responsible type */}
                  <div>
                    <FormLabel htmlFor="responsibleType" text="Rôle" />
                    <ResponsibleTypeSelectContainer>
                      <FormInputContainer
                        icon={<BiRename />}
                        input={
                          <select
                            id="responsibleType"
                            name="responsibleType"
                            placeholder="Sélectionner le rôle"
                            onChange={handleChangResponsable}
                            value={
                              formValuesResponsable.responsibleType
                                ? formValuesResponsable.responsibleType
                                : ""
                            }
                          >
                            <option value="">Sélectionner le rôle</option>
                            {REPONSABLE_TYPES.filter(
                              (r) =>
                                !responsibles
                                  .filter((r) => !r.deleted)
                                  .some((res) => res.responsibleType === r)
                            ).map((r) => (
                              <option key={r} value={r}>
                                {r}
                              </option>
                            ))}
                          </select>
                        }
                      />
                    </ResponsibleTypeSelectContainer>
                  </div>

                  {/* agent */}
                  <div>
                    <FormLabel htmlFor="agentId" text="Qui a ce rôle ?" />
                    <FormInputContainer
                      icon={<i className="ri-shield-user-line" />}
                      input={
                        <select
                          id="agentId"
                          value={
                            formValuesResponsable.agentId
                              ? formValuesResponsable.agentId
                              : ""
                          }
                          name="agentId"
                          onChange={handleChangResponsable}
                        >
                          <option value="">Sélection d'une personne</option>
                          {agents &&
                            agents.map((agent, key) => {
                              return (
                                <option key={key} value={agent.id}>
                                  {agent.firstName} {agent.lastName}
                                </option>
                              );
                            })}
                        </select>
                      }
                    />
                  </div>
                  <div>
                    <Button text="Ajouter" onClick={onAddResponsable} />
                  </div>
                </ResponsableLine>
              </div>

              {/* form errors */}
              {errorMessage && (
                <FormErrorMessageContainer errorMessage={errorMessage} />
              )}

              {/* submit btn */}
              <div className="submit-btn-container">
                <Button text={actionString} onClick={handleSubmit} />
              </div>
            </div>
          </Form>
        </FormScrollable>
      </FormContainer>

      {/* TOAST */}
      {toastVisible && toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastVisible(false);
          }}
          endedTimer={() => {
            _setToastVisible(false);
          }}
        />
      )}

      {/* confirm modal */}
      {confirmModal && (
        <Modal onClose={() => _setConfirmModal(null)}>
          <ConfirmModalContainer>
            <div className="message">{confirmModal.message}</div>
            <div className="buttons">
              <Button
                text="Oui"
                onClick={() => confirmModal.confirmedAction()}
              />
              <Button text="Non" onClick={() => _setConfirmModal(null)} />
            </div>
          </ConfirmModalContainer>
        </Modal>
      )}
    </FormBase>
  );
};

export default EditDivisionForm;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const FormContainer = styled.div`
  padding: 40px 10%;
`;

const FormScrollable = styled.div`
  width: 100%;
  overflow: auto;
  padding: 0px 20px;
`;

const Form = styled.form`
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.4em;

    .submit-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HistoriqueResponsibles = styled.div`
  h4 {
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    color: ${COLOR_TITLE};
  }

  .actual-responsibles {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const ResponsibleCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px 32px;
  border-radius: 8px;
  color: ${COLOR_TEXT};
  border: 1px solid ${COLOR_TEXT};

  h4 {
    margin-bottom: 0;
    color: ${COLOR_TEXT};
  }
`;

const EditDeleteIcons = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 4px;
  color: ${COLOR_TEXT};

  :hover {
    color: ${COLOR_RED_BRIGHT};
  }
`;

const ConfirmModalContainer = styled.div`
  padding: 20px;

  .message {
    text-align: center;
    color: ${COLOR_TEXT};
    font-size: 0.9rem;

    // ========= MEDIA QUERIES - ConfirmModalContainer ============
    @media (max-width: 1000px) {
      font-size: 2.5vw;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;

const ResponsibleTypeSelectContainer = styled.div``;

const ResponsableLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 80px;
  gap: 16px;

  button {
    margin-top: 23px;
  }
`;
