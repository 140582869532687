import { startOfMonth } from "date-fns";

export const convertDateToString = (theDate: Date) => {
  if (theDate) {
    const months = [
      "Jan",
      "Fév",
      "Mar",
      "Avr",
      "Mai",
      "Juin",
      "Juil",
      "Aout",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(theDate);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${months[month]} ${year}`;
  } else {
    return null;
  }
};

export const isDate1EarlierThanDate2 = (
  firstDate: string | Date,
  secondDate: string | Date
) => {
  const date1 = new Date(firstDate);
  const date2 = new Date(secondDate);

  if (date1 < date2) {
    return true;
  }

  return false;
};

export const changeDateFormat = (theDate: Date | string) => {
  const date = new Date(theDate);
  const formatDate =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const formatMonth =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth();
  return [date.getFullYear(), formatMonth, formatDate].join("-");
};

export const changeDateFormatDDMMYYYY = (theDate: Date | string) => {
  const date = new Date(theDate);
  const formatDate =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  let formatMonth = "";

  if (date.getMonth() + 1 < 10)
    formatMonth = "0" + (date.getMonth() + 1).toString();
  else formatMonth = (date.getMonth() + 1).toString();

  return [formatDate, formatMonth, date.getFullYear()].join("/");
};

export const changeDateFormatYYYYMMDD = (theDate: Date | string) => {
  if (theDate) {
    const date = new Date(theDate);
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  } else {
    return null;
  }
};

export const changeDateFormatFromYYYYMMDD = (theDate: string) => {
  const theDateArray = theDate.split("-");
  if (theDateArray.length === 3) {
    const date = new Date(
      +theDateArray[0],
      +theDateArray[1] - 1,
      +theDateArray[2]
    );
    return date;
  } else {
    return null;
  }
};

export const convertDateAndTimeToString = (theDate: Date) => {
  const months = [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aout",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(theDate);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const hour = date.getHours();
  let minutes = date.getMinutes() + "";

  if (minutes.length === 1) {
    minutes = "0" + minutes;
  }

  return `${day} ${months[month]} ${year} / ${hour}:${minutes}`;
};

export function today(now = new Date()) {
  return (
    String(now.getFullYear()) +
    "-" +
    String(now.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(now.getDate()).padStart(2, "0")
  );
}

export function getToday(now = new Date()) {
  return new Date(now);
}

export function getMonth(now = new Date()) {
  return startOfMonth(now);
}

export function designBetweenTwoDate(date1 = new Date(), date2 = new Date()) {
  const nbDays = Math.round(
    (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24)
  );
  let nbMonths = Math.round(nbDays / 30.44);
  if (nbMonths < 0) {
    nbMonths *= -1;
  }

  return `${Math.floor(nbMonths / 12)} ans ${
    nbMonths - Math.floor(nbMonths / 12) * 12
  } mois`;
}

/*console.log(
  "differenceInMonths",
  designBetweenTwoDate(
    new Date(2024, 3, 1, 0, 0, 0),
    new Date(2025, 2, 31, 0, 0, 0)
  )
); // 11
*/

//01/04/2024	01/04/2024	31/03/2025	0 ans 11 mois
