import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import styled from "styled-components";
import {
  COLOR_BLUE_BUTTON,
  COLOR_BORDER_IDLE_PAGE,
  COLOR_BTN,
  COLOR_BTN_HOVER,
  COLOR_DISABLED,
  COLOR_ERROR_MESSAGE,
  COLOR_GRAY_ARRAY,
  COLOR_TABLE_HEADER,
  COLOR_TABLE_ROW_BORDER,
  COLOR_TABLE_TEXT,
  COLOR_TEXT,
  COLOR_TEXT_HEADER,
  COLOR_TEXT_TABLE_ROW_ACTIONS,
  COLOR_TITLE,
  COLOR_WHITE,
  COLOR_WHITE2_ARRAY,
} from "../../constants/cts_colors";
import { ITableData } from "../../interfaces/table";
import { MdCloudUpload } from "react-icons/md";
import { RiInboxUnarchiveLine } from "react-icons/ri";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import TableRowActions from "./TableRowActions";
import * as XLSX from "xlsx";
import ReactDOMServer from "react-dom/server";
import { CiExport } from "react-icons/ci";

const SELECT_COLUMN_CLASS_NAME = "select";
const ACTIONS_COLUMN_CLASS_NAME = "actions";

const TableComponent = ({
  data,
  onAddBtnClicked,
  onDeleteSelectedRows,
  onRestoreSelectedRows,
  onAnonymiseSelectedRows,
  noDataMessage,
  maxHeight,
  onImportBtnClicked,
  hideHeader,
  rowActions,
  onRowActionSelected,
  onUpdatedSelectedRowsIds,
  onExport,
  onFilter,
  displayTableHeader,
}: {
  data: ITableData | null;
  onAddBtnClicked?: MouseEventHandler<HTMLButtonElement>;
  onDeleteSelectedRows?: MouseEventHandler<HTMLButtonElement>;
  onRestoreSelectedRows?: MouseEventHandler<HTMLButtonElement>;
  onAnonymiseSelectedRows?: MouseEventHandler<HTMLButtonElement>;
  noDataMessage?: string;
  maxHeight?: string;
  onImportBtnClicked?: MouseEventHandler<HTMLButtonElement>;
  hideHeader?: boolean;
  rowActions?: object;
  onRowActionSelected?: Function;
  onUpdatedSelectedRowsIds?: Function;
  onExport?: Function;
  onFilter?: Function;
  displayTableHeader?: boolean;
}) => {
  const [columns, _setColumns] = useState<any>(null);
  const [rows, _setRows] = useState<any>(null);
  const [sortBy, _setSortBy] = useState<any>();
  const [numberPerPage, _setNumberPerPage] = useState<number>(10);
  const [sortDirection, _setSortDirection] = useState<"asc" | "desc">("asc");
  const [selectColumnIndex, _setSelectColumnIndex] = useState<number | null>(
    null
  );
  const [actionsColumnIndex, _setActionsColumnIndex] = useState<number | null>(
    null
  );
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [tableScrollPosition, _setTableScrollPosition] = useState<number>(0);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);
  const [tableHeadBottomPosition, _setTableHeadBottomPosition] = useState<
    number | null
  >(null);
  const [selectedRowsIds, _setSelectedRowsIds] = useState<Array<number>>([]);
  const SELECT_COLUMN_KEY = "select";
  const ACTIONS_COLUMN_KEY = "actions";

  // get column index by column key
  const getColumnIndexByColumnKey = ({
    columnKeys,
    columnKey,
  }: {
    columnKeys: Object;
    columnKey: string;
  }) => {
    const keysArray = [];
    let returnIndex = null;

    if (typeof columnKeys === "object") {
      for (const [key] of Object.entries(columnKeys)) {
        keysArray.push(key);
      }
    }

    keysArray.forEach((key, index) => {
      if (key === columnKey) {
        returnIndex = index;
      }
    });

    return returnIndex;
  };

  // set the data
  useEffect(() => {
    if (data) {
      // Tri par défaut (LASTNAME pour les noms)
      let initialSortedRows = [...data.rows].sort((a, b) => {
        const aValue = a.tableData.lastName;
        const bValue = b.tableData.lastName;

        if (!aValue) return 1;
        if (!bValue) return -1;

        return aValue.localeCompare(bValue, "fr", { numeric: true });
      });

      // initialSortedRows = initialSortedRows.slice(0, numberPerPage);

      _setColumns(data.columns);
      _setRows(initialSortedRows);

      if (data.rows.length > 0) {
        _setSelectColumnIndex(
          getColumnIndexByColumnKey({
            columnKeys: data.rows[0].tableData,
            columnKey: SELECT_COLUMN_KEY,
          })
        );
        _setActionsColumnIndex(
          getColumnIndexByColumnKey({
            columnKeys: data.rows[0].tableData,
            columnKey: ACTIONS_COLUMN_KEY,
          })
        );
      }
    }
  }, [data]);

  // get and return all column values of the row
  const getColumnKeysFromTheRowObject = (row: Object) => {
    const columnKeys: Array<any> = [];
    if (typeof row === "object") {
      for (const [key] of Object.entries(row)) {
        columnKeys.push(key);
      }
    }
    return columnKeys;
  };

  // get column class name
  const getColumnClassName = (currentColumnIndex: number) => {
    let className = "";

    if (!data) {
      return className;
    }

    if (currentColumnIndex === selectColumnIndex) {
      className = SELECT_COLUMN_CLASS_NAME;
    } else if (currentColumnIndex === actionsColumnIndex) {
      className = ACTIONS_COLUMN_CLASS_NAME;
    }
    return className;
  };

  function textContent(elem: React.ReactElement | string | number): string {
    if (!elem) {
      return "";
    }
    if (typeof elem === "string" || typeof elem === "number") {
      return elem.toString();
    }
    const children = elem.props && elem.props.children;
    if (children instanceof Array) {
      return children.map(textContent).join("");
    }
    return textContent(children);
  }

  const onPrepareExport = () => {
    /*let csvRows = [];
    csvRows.push(
      columns.filter((c: string) => c !== "" && c !== "Actions").join(",")
    );
    csvRows = csvRows.concat(
      rows.map((row: any) =>
        getColumnKeysFromTheRowObject(row.tableData)
          .filter((c: string) => c !== "" && c !== "select" && c !== "actions")
          .map((columnKey) => textContent(getColumnValue({ row, columnKey })))
          .join(",")
      )
    );
    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "export.csv");
    a.click();

    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet([
      columns.filter((c: string) => c !== "" && c !== "Actions"),
      ...rows.map((row: any) =>
        getColumnKeysFromTheRowObject(row.tableData)
          .filter((c: string) => c !== "" && c !== "select" && c !== "actions")
          .map((columnKey) => textContent(getColumnValue({ row, columnKey })))
      ),
    ]);
    console.log("WS", ws);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "export.xlsx");

    if (onExport) {
      onExport();
    }
  };

  // UPDATE TABLE SCROLL
  useEffect(() => {
    if (tableContainerRef.current && rows && rows.length > 0) {
      const tableContainer = tableContainerRef.current;

      const updateTableScroll = (event: any) => {
        _setTableScrollPosition(event.target.scrollTop);
      };

      if (tableContainer) {
        tableContainer.addEventListener("scroll", updateTableScroll);

        // Use the same function reference for removeEventListener
        return () => {
          tableContainer.removeEventListener("scroll", updateTableScroll);
        };
      }
    }
  }, [tableContainerRef.current, rows]);

  // SET THE TABLE HEAD
  useEffect(() => {
    if (tableHeadRef.current) {
      _setTableHeadBottomPosition(
        tableHeadRef.current.getBoundingClientRect().bottom
      );
    }
  }, [tableHeadRef.current]);

  // GET COLUMN VALUE
  const getColumnValue = ({ row, columnKey }: any) => {
    switch (columnKey) {
      // case SELECT_COLUMN_KEY:
      //   return (
      //     row.tableData.select && (
      //       <input
      //         type="checkbox"
      //         value={row.infos.id}
      //         checked={selectedRowsIds.indexOf(row.infos.id) !== -1}
      //         onChange={(e) => {
      //           const newList = toggleSelectRow(parseInt(e.target.value));
      //           _setSelectedRowsIds([...newList]);
      //           if (onUpdatedSelectedRowsIds) {
      //             onUpdatedSelectedRowsIds([...newList]);
      //           }
      //         }}
      //       />
      //     )
      //   );
      case ACTIONS_COLUMN_KEY:
        return row.tableData.actions && rowActions && onRowActionSelected ? (
          <TableRowActions
            row={row.infos}
            actions={rowActions}
            onActionSelected={(infos: { row: any; action: string }) =>
              onRowActionSelected(infos)
            }
            tableScrollPosition={tableScrollPosition}
            tableHeadBottomPosition={tableHeadBottomPosition}
          />
        ) : (
          row.tableData[columnKey]
        );
      default:
        return row.tableData[columnKey];
    }
  };

  // ---------- SORT
  // HANDLE SORT DATA
  const handleSort = (column: string) => {
    if (sortBy === column) {
      // Si on clique sur la même colonne, on inverse la direction
      _setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Nouvelle colonne, on réinitialise la direction
      _setSortDirection("asc");
      _setSortBy(column);
    }
  };

  // ---------- SELECT PAGES ------
  // const handleSelectChange = (value: string) => {
  //   _setNumberPerPage(parseInt(value));
  // };

  // SORT
  useEffect(() => {
    if (!data || !sortBy) return;

    let sortedRows = [...data.rows].sort((a, b) => {
      let sortType = "";

      switch (sortBy) {
        // AGENTS
        case "Matricule":
          sortType = "registrationNumber";
          break;
        case "Nom":
          sortType = "lastName";
          break;
        case "Prénom":
          sortType = "firstName";
          break;
        case "Date de naissance":
          sortType = "birthDate";
          break;
        case "Âge":
          sortType = "age";
          break;
        case "Genre":
          sortType = "sex";
          break;
        case "Téléphone Professionnel":
          sortType = "professionalPhoneNumber";
          break;
        case "Catégorie agent":
          sortType = "catAgent";
          break;
        case "Corps agent":
          sortType = "corsAgent";
          break;
        case "Acronyme service DLM":
          sortType = "acronymeService";
          break;
        case "Designation service DLM":
          sortType = "designationService";
          break;
        case "Responsable du service":
          sortType = "responsableService";
          break;
        case "Responsable adjoint du service":
          sortType = "responsableAdjoint";
          break;
        case "Pôle/Equipe/Cellule":
          sortType = "poleEquipeCellule";
          break;
        case "Responsable Pôle/Equipe/cellule":
          sortType = "repsonsablePole";
          break;
        case "Post occupé":
          sortType = "postOccupe";
          break;
        case "BAP post occupé":
          sortType = "bapPostOccupe";
          break;
        case "Cat.Corps poste occupé":
          sortType = "catCorpsPostOccupe";
          break;
        case "Emploi type poste occupé":
          sortType = "emploi";
          break;
        case "Quotite":
          sortType = "quotite";
          break;
        case "Statut":
          sortType = "statut";
          break;
        case "Type contrat si contractuel":
          sortType = "typeContrat";
          break;
        case "Date 1er CDD":
          sortType = "date1erCDD";
          break;
        case "Début de CDD actuel":
          sortType = "debutCDD";
          break;
        case "Fin de CDD actuel":
          sortType = "finCDD";
          break;
        case "Durée total CDD en année/mois":
          sortType = "dureeTotalCDD";
          break;
        case "Obtention LA/TA":
          sortType = "obtentionLATA";
          break;
        case "Observations":
          sortType = "observations";
          break;
        case "Situation particulière":
          sortType = "situationParticuliere";
          break;
        case "Agent en TT":
          sortType = "agentEnTT";
          break;
        case "email SU":
          sortType = "email";
          break;
        // SERVICES DLM
        case "Acronyme Service":
          sortType = "acronym";
          break;
        case "Désignation service DLM":
          sortType = "name";
          break;
        case "Responsable du service":
          sortType = "responsible";
          break;
        case "Responsable adjoint du service":
          sortType = "responsibleAssistant";
          break;
      }

      const aValue = a.tableData[sortType];
      let bValue = b.tableData[sortType];

      // Gestion des valeurs null ou undefined
      if (!aValue) return 1;
      if (!bValue) return -1;

      // Gestion spéciale pour l'emploi
      if (sortType === "emploi") {
        let aString = aValue ? aValue : "";
        let bString = bValue ? bValue : "";

        aString = ReactDOMServer.renderToStaticMarkup(aString);
        bString = ReactDOMServer.renderToStaticMarkup(bString);

        aString.replace(/<\/?[^>]+(>|$)/g, "");
        bString.replace(/<\/?[^>]+(>|$)/g, "");

        return sortDirection === "asc"
          ? aString.localeCompare(bString, "fr", { numeric: true })
          : bString.localeCompare(aString, "fr", { numeric: true });
      }

      // Gestion spéciale pour le tableau situationParticuliere
      if (sortType === "situationParticuliere") {
        let aString = Array.isArray(aValue) ? aValue[0] || "" : "";
        let bString = Array.isArray(bValue) ? bValue[0] || "" : "";

        aString = ReactDOMServer.renderToStaticMarkup(aString);
        bString = ReactDOMServer.renderToStaticMarkup(bString);

        aString.replace(/<\/?[^>]+(>|$)/g, "");
        bString.replace(/<\/?[^>]+(>|$)/g, "");

        return sortDirection === "asc"
          ? aString.localeCompare(bString, "fr", { numeric: true })
          : bString.localeCompare(aString, "fr", { numeric: true });
      }

      // Gestion spéciale pour les dates
      if (
        sortType === "birthDate" ||
        sortType === "date1erCDD" ||
        sortType === "debutCDD" ||
        sortType === "finCDD"
      ) {
        const aDate = a.tableData[sortType]
          ? a.tableData[sortType].split("/").reverse().join("-")
          : null;
        const bDate = b.tableData[sortType]
          ? b.tableData[sortType].split("/").reverse().join("-")
          : null;

        if (!aDate) return 1;
        if (!bDate) return -1;

        return sortDirection === "asc"
          ? new Date(aDate).getTime() - new Date(bDate).getTime()
          : new Date(bDate).getTime() - new Date(aDate).getTime();
      }

      const aString =
        typeof aValue === "object" ? textContent(aValue) : String(aValue);
      const bString =
        typeof bValue === "object" ? textContent(bValue) : String(bValue);

      // Tri en tenant compte de la direction
      const compareResult = aString.localeCompare(bString, "fr", {
        numeric: true,
      });
      return sortDirection === "asc" ? compareResult : -compareResult;
    });

    // console.log("SORTED ROWS", sortedRows);
    // sortedRows = sortedRows.slice(0, numberPerPage);
    _setRows(sortedRows);
  }, [sortBy, sortDirection]);

  // SELECT NUMBER PER PAGE
  // useEffect(() => {
  //   // console.log("NUMBER PER PAGE", numberPerPage);

  //   if (data?.rows) {
  //     let newRows = [...data.rows];
  //     newRows = newRows.slice(0, numberPerPage);
  //     _setRows(newRows);
  //   }
  // }, [numberPerPage]);

  // THE TABLE ------------------------------------------------------------------------------------
  return (
    <Wrapper style={{ maxHeight }}>
      {!hideHeader && (
        <TableHeader style={{ display: displayTableHeader ? "flex" : "none" }}>
          <div>
            <h1>
              Liste des Agents <span>({data?.rows.length} actifs)</span>
            </h1>
          </div>
          <div className="buttons">
            {onImportBtnClicked && (
              <button className="btn-export" onClick={onImportBtnClicked}>
                <MdCloudUpload className="icon" />
                Importer
              </button>
              // <button
              //   className="btn"
              //   onClick={onImportBtnClicked}
              //   title="Importer des Agents"
              // >
              //   <MdCloudUpload className="icon" />
              // </button>
            )}
            {/* {
              // if the delete function click trigger is set and
              // if there are selected rows
              onDeleteSelectedRows && (
                <button
                  title={"Archiver les Agents sélectionnés"}
                  disabled={
                    selectedRowsIds && selectedRowsIds.length < 1 ? true : false
                  }
                  style={{
                    opacity:
                      selectedRowsIds && selectedRowsIds.length < 1 ? 0.25 : 1,
                    cursor:
                      selectedRowsIds && selectedRowsIds.length < 1
                        ? "not-allowed"
                        : "pointer",
                  }}
                  className="btn"
                  onClick={onDeleteSelectedRows}
                >
                  <FiArchive className="icon" size={16} />
                </button>
              )
            } */}

            {
              // if the add function click trigger is set, show the button
              onRestoreSelectedRows && (
                <button
                  title="Restaurer les Agents sélectionnés"
                  disabled={
                    selectedRowsIds && selectedRowsIds.length < 1 ? true : false
                  }
                  style={{
                    opacity:
                      selectedRowsIds && selectedRowsIds.length < 1 ? 0.25 : 1,
                    cursor:
                      selectedRowsIds && selectedRowsIds.length < 1
                        ? "not-allowed"
                        : "pointer",
                  }}
                  className="btn"
                  onClick={onRestoreSelectedRows}
                >
                  <RiInboxUnarchiveLine className="icon" />
                </button>
              )
            }
            {onExport && (
              <button className="btn-export" onClick={() => onPrepareExport()}>
                <CiExport className="icon" />
                Exporter
              </button>
            )}
            {
              // if the add function click trigger is set, show the button
              onAddBtnClicked && (
                <button className="btn-add" onClick={onAddBtnClicked}>
                  <AiOutlinePlus className="icon" />
                  <p>Ajouter un agent</p>
                </button>
              )
            }
          </div>
        </TableHeader>
      )}
      {rows && rows.length > 0 ? (
        <TableContainer ref={tableContainerRef}>
          <Table cellSpacing="0" cellPadding="0">
            <thead ref={tableHeadRef}>
              <tr>
                {columns &&
                  columns.length > 0 &&
                  columns.map((column: any, index: number) => (
                    <th
                      style={{
                        fontSize: "0.9rem",
                      }}
                      key={"table-column-" + index}
                      className={getColumnClassName(index)}
                    >
                      {/* !SELECT AND ACTIONS */}
                      {getColumnClassName(index) !== "select" &&
                        getColumnClassName(index) !== "actions" && (
                          <span
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              fontWeight: "bold",
                              width: "fit-content",
                            }}
                            onClick={() => {
                              handleSort(column);
                            }}
                          >
                            {column === ("" || " ") ? <>&nbsp;</> : column}{" "}
                            <div style={{ paddingTop: 4 }}>
                              {sortDirection === "asc" && sortBy === column ? (
                                <FiChevronUp className="icon" size={16} />
                              ) : (
                                <FiChevronDown className="icon" size={16} />
                              )}
                            </div>
                          </span>
                        )}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row: any, index: number) => (
                <tr key={"table-row-" + index}>
                  {getColumnKeysFromTheRowObject(row.tableData).map(
                    (columnKey, colIndex) => (
                      <td
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? COLOR_GRAY_ARRAY : COLOR_WHITE,
                        }}
                        key={"table-column-value-" + colIndex}
                        className={getColumnClassName(colIndex)}
                      >
                        {/* GENDER */}
                        {colIndex === 6 && (
                          <div className="sex-circle">
                            <span
                              style={{
                                backgroundColor:
                                  getColumnValue({ row, columnKey }) === "F"
                                    ? COLOR_ERROR_MESSAGE
                                    : COLOR_BLUE_BUTTON,
                              }}
                            ></span>
                            <p>{getColumnValue({ row, columnKey })}</p>
                          </div>
                        )}

                        {colIndex !== 6 &&
                          (getColumnValue({ row, columnKey }) === ""
                            ? ""
                            : getColumnValue({ row, columnKey }))}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      ) : (
        // if no data -> inform the user
        <div>
          {noDataMessage ? (
            <NoDataInfo>{noDataMessage}</NoDataInfo>
          ) : (
            <NoDataInfo>Aucune donnée disponible</NoDataInfo>
          )}
        </div>
      )}

      {/* PAGE CONTAINER */}
      {/* <PagesContainer>
        <div className="chevron disabled">
          <FaChevronLeft size={12} />
        </div>
        <div className="number selected">
          <span>1</span>
        </div>
        <div className="number">
          <span>2</span>
        </div>
        <div className="number">
          <span>3</span>
        </div>
        <div className="number">
          <span>4</span>
        </div>
        <div className="chevron">
          <FaChevronRight size={12} />
        </div>
        <div className="select">
          <select onChange={(e) => handleSelectChange(e.target.value)}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <p>/ Page</p>
        </div>
      </PagesContainer> */}
    </Wrapper>
  );
};

export default TableComponent;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.09px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  div {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 32px 40px;
    margin-bottom: 10px;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      font-family: "Ubuntu", sans-serif;
      font-size: 30px;
      font-weight: 700;
      line-height: 40px;
      text-align: left;
      text-underline-position: from-font;
      color: ${COLOR_TEXT_HEADER};
      text-decoration-skip-ink: none;

      span {
        font-size: 20px;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    transition: all 0.2ms ease;

    .btn {
      background-color: transparent;
      color: ${COLOR_BTN};
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: color 0.3s ease;

      .icon {
        width: 20px;
        height: 20px;
      }

      &:hover {
        color: ${COLOR_BTN_HOVER};
      }
    }

    .btn-export {
      border-radius: 8px;
      padding: 12px;
      gap: 8px;
      border: 1px solid ${COLOR_TITLE};
      background-color: ${COLOR_WHITE};
      font-family: "Ubuntu", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 18.38px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2 ease;
      height: fit-content;

      .icon {
        width: 20px;
        height: 20px;
      }

      :hover {
        background-color: ${COLOR_TITLE};
        color: ${COLOR_WHITE};
      }
    }

    .btn-add {
      border-radius: 8px;
      padding: 12px;
      gap: 8px;
      border: 1px solid ${COLOR_BLUE_BUTTON};
      background-color: ${COLOR_BLUE_BUTTON};
      font-family: "Ubuntu", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 18.38px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: ${COLOR_WHITE};
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.7ms ease;
      height: fit-content;
      width: fit-content;

      p {
        color: ${COLOR_WHITE};
      }

      .icon {
        width: 20px;
        height: 20px;
      }

      :hover {
        background-color: ${COLOR_WHITE};
        color: ${COLOR_BLUE_BUTTON};

        p {
          color: ${COLOR_BLUE_BUTTON};
        }
      }
    }
  }
`;

const TableContainer = styled.div`
  width: 100%;
  color: ${COLOR_TABLE_TEXT};
  border: none;
  overflow: auto;
`;

const Table = styled.table`
  position: relative;
  width: 100%;

  thead {
    position: sticky;
    top: 0;
    background-color: ${COLOR_TABLE_HEADER};
    z-index: 1;

    th {
      text-align: start;
      font-weight: 400;
      font-size: 12px;
      padding: 20px 0px;
      color: ${COLOR_TEXT};
      word-wrap: break-word;
      padding: 10px 15px;
      min-width: 120px;

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }

      &.${SELECT_COLUMN_CLASS_NAME} {
        position: sticky;
        left: 0px;
        background-color: ${COLOR_WHITE};
        min-width: 0px;
      }

      &.${ACTIONS_COLUMN_CLASS_NAME} {
        position: sticky;
        right: 0px;
        background-color: ${COLOR_WHITE};
        min-width: 0px;
      }
    }
  }

  tbody {
    // table data
    border: none;

    td {
      padding: 10px 15px;
      border-bottom: 5px solid ${COLOR_TABLE_ROW_BORDER};
      word-wrap: break-word;
      min-width: 120px;
      font-family: "Ubuntu", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18.38px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      .sex-circle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;

        span {
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
      }

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }

      &.${SELECT_COLUMN_CLASS_NAME} {
        position: sticky;
        left: 0px;
        /* background-color: ${COLOR_WHITE}; */
        min-width: 0px;

        input {
          border-radius: 8px;
          padding: 10px;
          width: 16px;
          height: 16px;
          accent-color: ${COLOR_TEXT_HEADER};
          cursor: pointer;
          border: 1px solid #ddd;
          outline: none;

          &:checked {
            border-radius: 8px;
          }
        }
      }

      &.${ACTIONS_COLUMN_CLASS_NAME} {
        position: sticky;
        right: 0px;
        min-width: 0px;

        button {
          border: 1px solid ${COLOR_BORDER_IDLE_PAGE};
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          padding: 10px;
          background-color: ${COLOR_WHITE};
          cursor: pointer;
          transition: all 0.2s ease;

          :hover {
            background-color: ${COLOR_BORDER_IDLE_PAGE};
            color: ${COLOR_WHITE};
          }
        }
      }

      .more-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        color: ${COLOR_TEXT};
      }
    }
  }
`;

const NoDataInfo = styled.div`
  width: 100%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR_TEXT};
  font-size: 0.8rem;
  border-radius: 10px;
`;

const PagesContainer = styled.div`
  padding: 24px 40px;
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: ${COLOR_WHITE};

  div {
    border-radius: 8px;
  }

  .chevron {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Neue Montreal", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.025em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border: 1px solid ${COLOR_BORDER_IDLE_PAGE};
    width: 40px;
    height: 40px;
    cursor: pointer;

    :hover {
      border-color: ${COLOR_DISABLED};
    }

    &.disabled {
      background-color: ${COLOR_WHITE2_ARRAY};
      border: 1px solid ${COLOR_WHITE2_ARRAY};
      color: ${COLOR_DISABLED};
      cursor: not-allowed;
    }
  }

  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Neue Montreal", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.025em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border: 1px solid ${COLOR_BORDER_IDLE_PAGE};
    width: 40px;
    height: 40px;
    cursor: pointer;

    :hover {
      border-color: ${COLOR_DISABLED};
    }

    &.selected {
      border: 1px solid ${COLOR_TITLE};
    }
  }

  .select {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    select {
      border: 1px solid ${COLOR_BORDER_IDLE_PAGE};
      border-radius: 8px;
      padding: 10px;
      width: 127px;
      cursor: pointer;
      outline: none;

      :after {
        border-color: transparent transparent #fff transparent;
        top: 7px;
      }

      /* option {
        color: ${COLOR_TEXT_TABLE_ROW_ACTIONS};
        font-family: "Ubuntu", sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 18.38px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        width: 100%;
        padding: 8px 12px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: ${COLOR_BORDER_IDLE_PAGE};
        }
      } */
    }

    p {
      font-family: "Neue Montreal", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
`;
