import { useEffect, useState } from "react";
import BodyContainer from "../../components/BodyContainer";
import styled from "styled-components";
import { Navigate, useParams } from "react-router-dom";
import { useAgents } from "../../common/contexts/agentContext";
import { IAgentInfos } from "../../interfaces/agent";
import {
  changeDateFormatFromYYYYMMDD,
  changeDateFormatYYYYMMDD,
} from "../../utils/date";
import {
  COLOR_TEXT,
  COLOR_TITLE,
  COLOR_WHITE,
} from "../../constants/cts_colors";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { useReferensJobs } from "../../common/contexts/referensJobContext";
import { IReferensJobInfos } from "../../interfaces/referensJob";
import { getPageUrl } from "../../locales/i18n";
import PATH from "../../constants/cts_routes";

const MENU = [
  {
    id: "edit",
    title: "Modifier l'agent",
    img: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M7.99996 12.6811H12.5982M3.40173 12.6811H4.25728C4.50721 12.6811 4.63218 12.6811 4.74978 12.6529C4.85404 12.6279 4.95372 12.5866 5.04514 12.5306C5.14826 12.4674 5.23663 12.379 5.41335 12.2023L11.8319 5.78378C12.2551 5.36053 12.2551 4.67429 11.8319 4.25104C11.4086 3.82778 10.7224 3.82778 10.2991 4.25104L3.88059 10.6695C3.70387 10.8463 3.6155 10.9346 3.55231 11.0377C3.49628 11.1292 3.455 11.2288 3.42997 11.3331C3.40173 11.4507 3.40173 11.5757 3.40173 11.8256V12.6811Z"
          stroke="#1D2769"
          strokeWidth="1.53275"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    id: "emplois",
    title: "Emplois de l'agent",
    img: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M10.4 5.20029C10.4 4.64231 10.4 4.36332 10.3387 4.13442C10.1722 3.51325 9.68704 3.02807 9.06587 2.86163C8.83697 2.80029 8.55798 2.80029 8 2.80029C7.44202 2.80029 7.16303 2.80029 6.93413 2.86163C6.31296 3.02807 5.82777 3.51325 5.66133 4.13442C5.6 4.36332 5.6 4.64231 5.6 5.20029M3.92 13.6003H12.08C12.7521 13.6003 13.0881 13.6003 13.3448 13.4695C13.5706 13.3545 13.7542 13.1709 13.8692 12.9451C14 12.6884 14 12.3524 14 11.6803V7.12029C14 6.44823 14 6.1122 13.8692 5.8555C13.7542 5.62971 13.5706 5.44613 13.3448 5.33109C13.0881 5.20029 12.7521 5.20029 12.08 5.20029H3.92C3.24794 5.20029 2.91191 5.20029 2.65521 5.33109C2.42942 5.44613 2.24584 5.62971 2.13079 5.8555C2 6.1122 2 6.44823 2 7.12029V11.6803C2 12.3524 2 12.6884 2.13079 12.9451C2.24584 13.1709 2.42942 13.3545 2.65521 13.4695C2.91191 13.6003 3.24794 13.6003 3.92 13.6003Z"
          stroke="#1D2769"
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    id: "diplomes",
    title: "Formations de l'Agent",
    img: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M10.8964 9.21846V7.23549C10.8964 7.11708 10.8964 7.05787 10.8784 7.0056C10.8625 6.95937 10.8365 6.91727 10.8022 6.88234C10.7635 6.84284 10.7106 6.81636 10.6047 6.7634L7.59763 5.25988M2.31953 5.91964V10.4104C2.31953 10.6558 2.31953 10.7785 2.3578 10.8859C2.39164 10.9808 2.4468 11.0668 2.51902 11.1371C2.60073 11.2167 2.71226 11.2678 2.93532 11.37L7.1578 13.3053C7.3196 13.3795 7.40049 13.4166 7.48477 13.4312C7.55945 13.4442 7.63581 13.4442 7.71049 13.4312C7.79476 13.4166 7.87566 13.3795 8.03746 13.3053L12.2599 11.37C12.483 11.2678 12.5945 11.2167 12.6762 11.1371C12.7485 11.0668 12.8036 10.9808 12.8375 10.8859C12.8757 10.7785 12.8757 10.6558 12.8757 10.4104V5.91964M1 5.25988L7.36159 2.07908C7.44813 2.03581 7.49141 2.01417 7.5368 2.00566C7.577 1.99811 7.61826 1.99811 7.65846 2.00566C7.70385 2.01417 7.74712 2.03581 7.83367 2.07908L14.1953 5.25988L7.83367 8.44067C7.74712 8.48394 7.70385 8.50558 7.65846 8.5141C7.61826 8.52164 7.577 8.52164 7.5368 8.5141C7.49141 8.50558 7.44813 8.48395 7.36159 8.44067L1 5.25988Z"
          stroke="#1D2769"
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
];

const EditAgent = () => {
  const { id } = useParams();
  const { onGetAgentDetails, onUpdateAgentOnly } = useAgents();
  const { onGetAllReferensJobs } = useReferensJobs();
  const [agent, setAgent] = useState<IAgentInfos | null>(null);
  const [isBackHome, _setIsBackHome] = useState<boolean>(false);
  const [referensJobs, setReferensJobs] = useState<
    Array<IReferensJobInfos | null>
  >([]);
  const [addFormationName, _setAddFormationName] = useState<string>("");
  const [addFormationYear, _setAddFormationYear] = useState<string>("");
  const [sectionSelected, sectSectionSelected] = useState<string>("edit");
  const [onAddFormation, _setOnAddFormation] = useState<boolean>(false);
  const [onAddEmployment, _setOnAddEmployment] = useState<boolean>(false);
  const [addEmploymentName, _setAddEmploymentName] = useState<string>("");
  const [addEmploymentQuotite, _setAddEmploymentQuotite] = useState<number>(0);
  const [addEmploymentStatus, _setAddEmploymentStatus] = useState<string>("");
  const [addEmploymentStart, _setAddEmploymentStart] = useState<Date | null>(
    null
  );
  const [addEmploymentEnd, _setAddEmploymentEnd] = useState<Date | null>(null);
  const [referensJobId, _setReferensJobId] = useState<number>(-1);
  const [addEmploymentCatPostOccupe, _setAddEmploymentCatPostOccupe] =
    useState<string>("");
  const [addEmploymentBAPPosteOccupe, _setAddEmploymentBAPPosteOccupe] =
    useState<string>("");
  const [addEmploymentType, _setAddEmploymentType] = useState<string>("");

  // set title of the page
  useEffect(() => {
    document.title = "Édition d'un agent | Sorbonne";

    onGetAllReferensJobs().then((res) => {
      // console.log(res);
      setReferensJobs(res);
    });
  }, []);

  useEffect(() => {
    if (id) {
      sectSectionSelected("edit");
      onLoad();
    }
  }, [id]);

  const onLoad = () => {
    if (id) {
      onGetAgentDetails(+id).then((returnData) => {
        if (returnData) {
          setAgent(returnData);
        } else {
          setAgent({
            id: -1,
            firstName: "",
            lastName: "",
            sex: "",
            birthDate: new Date(),
            registrationNumber: "",
            maritalStatus: "",
            age: 0,
            professionalPhoneNumber: "",
            observationsDifficultJobs: "",
            observationsParticularSituations: "",
            email: "",
            bonusVacation: "",
            departureDate: new Date(),
            departureReason: "",
            division: null,
            category: "",
            corps: "",
            oldRegistrationNumber: "",
            mobilePhoneNumber: "",
            aideRenommage: "",
            loginGABS: "",
            dernierDiplomeObtenu: "",
            nomDernierDiplomeObtenu: "",
            tempsTravailHebdomataire: "",
            dacAnnuelReglementaire: "",
          });
        }
      });
    }
  };

  const onEdit = async () => {
    await onUpdateAgentOnly({
      id,
      ...agent,
    }).then(() => {
      _setIsBackHome(true);
    });
  };

  const switchTeleworking = (day: number) => {
    if (agent) {
      let teleworkingDays = agent.teleworking || { days: [] };
      if (teleworkingDays.days.length < 7) {
        teleworkingDays.days = [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ];
      }

      teleworkingDays.days[day] = !teleworkingDays.days[day];
      setAgent({ ...agent, teleworking: teleworkingDays });
    }
  };

  // ---------- EMPLOYMENTS ----------
  const onCreateEmployment = () => {
    let finalJobId = 0;

    referensJobs.some(function (el) {
      if (el?.jobTitle === addEmploymentName) {
        finalJobId = el.id;
      }
    });

    if (agent?.employments) {
      const myNextList = [...agent?.employments];
      myNextList.push({
        name: addEmploymentName, //CEST LE NOM DU JOB REFERENS referensJob.jobTitle
        startDate: addEmploymentStart,
        endDate: addEmploymentEnd,
        type: addEmploymentType,
        agentId: agent.id,
        referensJobId: finalJobId, //ID DU JOB REFERENS
        BAPPosteOccupe: addEmploymentBAPPosteOccupe,
        catPostOccupe: addEmploymentCatPostOccupe,
        id: -1,
        quotite: addEmploymentQuotite,
        status: addEmploymentStatus,
        code: "",
        family: "",
        subFamily: "",
      });
      setAgent({ ...agent, employments: myNextList });
      _setAddEmploymentName("");
      _setAddEmploymentStart(null);
      _setAddEmploymentEnd(null);
      _setAddEmploymentType("");
      _setAddEmploymentBAPPosteOccupe("");
      _setAddEmploymentCatPostOccupe("");
      _setAddEmploymentQuotite(0);
      _setAddEmploymentStatus("");
      _setReferensJobId(-1);
    }
  };

  const onDeleteEmployment = async (index: number) => {
    if (confirm("Voulez-vous supprimer cet emploi ?")) {
      if (agent?.employments) {
        agent.employments.splice(index, 1);
        setAgent({ ...agent, employments: agent.employments });
      }
    }
  };

  // ---------- FORMATIONS ----------
  const onCreateFormation = () => {
    if (agent?.formations) {
      const myNextList = [...agent?.formations];
      myNextList.push({
        id: -1,
        name: addFormationName,
        year: parseInt(addFormationYear),
        agentId: agent.id,
      });
      setAgent({ ...agent, formations: myNextList });
      _setAddFormationName("");
      _setAddFormationYear("");
    }
  };

  const onDeleteFormation = async (index: number) => {
    if (confirm("Voulez-vous supprimer cette formation ?")) {
      if (agent?.formations) {
        agent.formations.splice(index, 1);
        setAgent({ ...agent, formations: agent.formations });
      }
    }
  };

  // console.log(agent);
  if (isBackHome) {
    return <Navigate to={getPageUrl(PATH.agents)} />;
  }

  return (
    <BodyContainer title="Édition d'un agent" noScroll={true}>
      <Header>
        <h1>
          {id === "-1" ? "Ajouter une fiche agent" : "Modifier une fiche agent"}
        </h1>
        <div className="action-save" onClick={onEdit}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13.6365 4L5.63655 12L2.00018 8.36364"
              stroke="white"
              strokeWidth="1.45455"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>Enregistrer les modifications</p>
        </div>
      </Header>
      <Content>
        <LeftContent>
          <div className="header-of-left">
            <div>
              <p>#{agent?.registrationNumber}</p>
              <p>
                {(agent?.lastName || "").toUpperCase()} {agent?.firstName}
              </p>
            </div>
            <p>{agent?.email}</p>
            <p>{agent?.professionalPhoneNumber}</p>
          </div>
          <div className="actions-left">
            <p className="bold">Informations</p>
            <p className="acronym">{agent?.service?.acronym}</p>
            {MENU.map((m) => (
              <div
                key={m.id}
                className={sectionSelected === m.id ? "selected" : ""}
                onClick={() => sectSectionSelected(m.id)}
              >
                {m.img}
                <p className="bold">{m.title}</p>
              </div>
            ))}
          </div>
        </LeftContent>
        <RightContent>
          {sectionSelected === "edit" && (
            <SectionEdit>
              <div>
                <label>Nom</label>
                <div className="input">
                  <input
                    type="text"
                    value={(agent?.lastName || "").toUpperCase()}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({ ...agent, lastName: e.target.value });
                      }
                    }}
                  />
                </div>
                <label>Prénom</label>
                <div className="input">
                  <input
                    type="text"
                    value={agent?.firstName ? agent.firstName : ""}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({ ...agent, firstName: e.target.value });
                      }
                    }}
                  />
                </div>
                <label>Email</label>
                <div className="input">
                  <input
                    type="text"
                    value={agent?.email ? agent.email : ""}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({ ...agent, email: e.target.value });
                      }
                    }}
                  />
                </div>
                <label>Numéro de téléphone fixe</label>
                <div className="input">
                  <input
                    type="text"
                    value={
                      agent?.professionalPhoneNumber
                        ? agent?.professionalPhoneNumber
                        : ""
                    }
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          professionalPhoneNumber: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <label>Numéro de téléphone portable</label>
                <div className="input">
                  <input
                    type="text"
                    value={
                      agent?.mobilePhoneNumber ? agent?.mobilePhoneNumber : ""
                    }
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          mobilePhoneNumber: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <label>Aide renommage</label>
                <div className="input">
                  <input
                    type="text"
                    value={agent?.aideRenommage ? agent?.aideRenommage : ""}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          aideRenommage: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <label>Login GABS</label>
                <div className="input">
                  <input
                    type="text"
                    value={agent?.loginGABS ? agent?.loginGABS : ""}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          loginGABS: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <label>Genre</label>
                <select
                  value={agent?.sex ? agent.sex : ""}
                  onChange={(e) => {
                    if (agent) {
                      setAgent({ ...agent, sex: e.target.value });
                    }
                  }}
                >
                  <option value="M">Homme</option>
                  <option value="F">Femme</option>
                </select>
                <label>Date de naissance</label>
                <input
                  type="date"
                  value={
                    agent && agent.birthDate
                      ? changeDateFormatYYYYMMDD(agent.birthDate) || ""
                      : ""
                  }
                  onChange={(e) => {
                    if (agent) {
                      setAgent({
                        ...agent,
                        birthDate:
                          changeDateFormatFromYYYYMMDD(e.target.value) ||
                          new Date(),
                      });
                    }
                  }}
                />
              </div>
              <div>
                <label>Catégorie</label>
                <div className="input">
                  <select
                    value={agent?.category ? agent.category : ""}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({ ...agent, category: e.target.value });
                      }
                    }}
                  >
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                  </select>
                </div>
                <label>Corps</label>
                <div className="input">
                  <select
                    value={agent?.corps ? agent.corps : ""}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({ ...agent, corps: e.target.value });
                      }
                    }}
                  >
                    <option value="IGR">IGR</option>
                    <option value="IGE">IGE</option>
                    <option value="ASI">ASI</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                  </select>
                </div>
                <label>Date de départ</label>
                <input
                  type="date"
                  value={
                    agent && agent.departureDate
                      ? changeDateFormatYYYYMMDD(agent.departureDate) || ""
                      : ""
                  }
                  onChange={(e) => {
                    if (agent) {
                      setAgent({
                        ...agent,
                        departureDate:
                          changeDateFormatFromYYYYMMDD(e.target.value) ||
                          new Date(),
                      });
                    }
                  }}
                />
                <label>Raison du départ</label>
                <div className="input">
                  <input
                    type="text"
                    value={agent?.departureReason}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({ ...agent, departureReason: e.target.value });
                      }
                    }}
                  />
                </div>
                <label>Dernier diplôme obtenu</label>
                <div className="input">
                  <input
                    type="text"
                    value={agent?.dernierDiplomeObtenu}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          dernierDiplomeObtenu: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <label>Nom dernier diplôme obtenu</label>
                <div className="input">
                  <input
                    type="text"
                    value={agent?.nomDernierDiplomeObtenu}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          nomDernierDiplomeObtenu: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <label>
                  Temps de travail hebdomataire (hors déduction aménagement
                  d'horaire)
                </label>
                <div className="input">
                  <input
                    type="text"
                    value={agent?.tempsTravailHebdomataire}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          tempsTravailHebdomataire: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <label>
                  DAC annuel règlementaire (hors déduction aménagement
                  d'horaire)
                </label>
                <div className="input">
                  <input
                    type="text"
                    value={agent?.dacAnnuelReglementaire}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          dacAnnuelReglementaire: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <label>Jour de télétravail</label>
                <div className="telework-days">
                  <p
                    onClick={() => switchTeleworking(0)}
                    className={
                      agent && agent.teleworking && agent.teleworking.days[0]
                        ? "selected"
                        : ""
                    }
                  >
                    Lundi
                  </p>
                  <p
                    onClick={() => switchTeleworking(1)}
                    className={
                      agent && agent.teleworking && agent.teleworking.days[1]
                        ? "selected"
                        : ""
                    }
                  >
                    Mardi
                  </p>
                  <p
                    onClick={() => switchTeleworking(2)}
                    className={
                      agent && agent.teleworking && agent.teleworking.days[2]
                        ? "selected"
                        : ""
                    }
                  >
                    Mercredi
                  </p>
                  <p
                    onClick={() => switchTeleworking(3)}
                    className={
                      agent && agent.teleworking && agent.teleworking.days[3]
                        ? "selected"
                        : ""
                    }
                  >
                    Jeudi
                  </p>
                  <p
                    onClick={() => switchTeleworking(4)}
                    className={
                      agent && agent.teleworking && agent.teleworking.days[4]
                        ? "selected"
                        : ""
                    }
                  >
                    Vendredi
                  </p>
                  <p
                    onClick={() => switchTeleworking(5)}
                    className={
                      agent && agent.teleworking && agent.teleworking.days[5]
                        ? "selected"
                        : ""
                    }
                  >
                    Samedi
                  </p>
                </div>
              </div>
              <div>
                <label>Numéro de matricule</label>
                <div className="input">
                  <input
                    type="text"
                    value={
                      agent?.registrationNumber ? agent.registrationNumber : ""
                    }
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          registrationNumber: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <label>Ancien numéro de matricule</label>
                <div className="input">
                  <input
                    type="text"
                    value={
                      agent?.oldRegistrationNumber
                        ? agent.oldRegistrationNumber
                        : ""
                    }
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          oldRegistrationNumber: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <label>Congés Bonifiés</label>
                <div className="input">
                  <input
                    type="text"
                    value={agent?.bonusVacation ? agent.bonusVacation : ""}
                    onChange={(e) => {
                      if (agent) {
                        setAgent({ ...agent, bonusVacation: e.target.value });
                      }
                    }}
                  />
                </div>
                <label>Observations</label>
                <div className="textarea">
                  <textarea
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          observationsDifficultJobs: e.target.value,
                        });
                      }
                    }}
                    value={
                      agent?.observationsDifficultJobs
                        ? agent.observationsDifficultJobs
                        : ""
                    }
                  />
                </div>
                <label>Situation particulière</label>
                <div className="textarea">
                  <textarea
                    onChange={(e) => {
                      if (agent) {
                        setAgent({
                          ...agent,
                          observationsParticularSituations: e.target.value,
                        });
                      }
                    }}
                    value={
                      agent?.observationsParticularSituations
                        ? agent.observationsParticularSituations
                        : ""
                    }
                  />
                </div>
              </div>
            </SectionEdit>
          )}
          {sectionSelected === "emplois" && (
            <SectionEmplois>
              {/* <p>
                Ici faire une liste pour remplir la table emplois et l'envoyer la
                table directement dans le upgradeAgentOnly, ajouter / modifier /
                supprimer
              </p> */}
              {/* <p>{JSON.stringify(agent?.employments)}</p> */}
              <div className="emplois-header">
                <h3>Emplois de l’agent</h3>
                <div
                  className="emplois-header-actions"
                  style={{
                    borderBottom: !onAddEmployment
                      ? `1px solid ${COLOR_TEXT}`
                      : "0px",
                  }}
                  onClick={() => {
                    _setOnAddEmployment(!onAddEmployment);
                  }}
                >
                  {!onAddEmployment ? (
                    <IoIosAdd size={24} color={COLOR_TEXT} />
                  ) : (
                    <IoIosRemove size={24} color={COLOR_TEXT} />
                  )}
                  <p>Ajouter un emploi</p>
                </div>
              </div>

              {/* EMPLOI CREATION */}
              <div
                className="header-create"
                style={{ display: onAddEmployment ? "grid" : "none" }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexBasis: "auto",
                      flexDirection: "column",
                      gap: 16,
                    }}
                  >
                    {/* name */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>Emploi référent</label>
                      <select
                        value={addEmploymentName ? addEmploymentName : ""}
                        onChange={(e) => {
                          _setAddEmploymentName(e.target.value);
                        }}
                      >
                        <option value=""></option>
                        {referensJobs &&
                          referensJobs.map((job) => {
                            return (
                              <option value={job?.jobTitle} key={job?.jobTitle}>
                                {job?.jobTitle}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {/* start date */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>Date de début</label>
                      <div>
                        <input
                          type="date"
                          value={
                            addEmploymentStart
                              ? changeDateFormatYYYYMMDD(addEmploymentStart) ||
                                ""
                              : ""
                          }
                          onChange={(e) => {
                            _setAddEmploymentStart(new Date(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                    {/* end date */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>Date de fin</label>
                      <div>
                        <input
                          type="date"
                          value={
                            addEmploymentEnd
                              ? changeDateFormatYYYYMMDD(addEmploymentEnd) || ""
                              : ""
                          }
                          onChange={(e) => {
                            _setAddEmploymentEnd(new Date(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 16,
                    }}
                  >
                    {/* quotite */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>Quotité</label>
                      <div className="input">
                        <input
                          type="number"
                          min={0}
                          value={
                            addEmploymentQuotite ? addEmploymentQuotite : ""
                          }
                          onChange={(e) => {
                            _setAddEmploymentQuotite(+e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    {/* type */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>Type</label>
                      <div className="input">
                        <input
                          type="text"
                          value={addEmploymentType ? addEmploymentType : ""}
                          onChange={(e) => {
                            _setAddEmploymentType(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {/* status */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>Statut</label>
                      <div className="input">
                        <input
                          type="text"
                          value={addEmploymentStatus ? addEmploymentStatus : ""}
                          onChange={(e) => {
                            _setAddEmploymentStatus(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    marginTop: 16,
                    marginBottom: 16,
                  }}
                >
                  {/* BAP */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>BAP Poste Occupé</label>
                    <div className="input">
                      <input
                        type="text"
                        value={
                          addEmploymentBAPPosteOccupe
                            ? addEmploymentBAPPosteOccupe
                            : ""
                        }
                        onChange={(e) => {
                          _setAddEmploymentBAPPosteOccupe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/* CatPostOccupe */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Cat Poste Occupé</label>
                    <div className="input">
                      <input
                        type="text"
                        value={
                          addEmploymentCatPostOccupe
                            ? addEmploymentCatPostOccupe
                            : ""
                        }
                        onChange={(e) => {
                          _setAddEmploymentCatPostOccupe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="action-save"
                    style={{
                      opacity:
                        addEmploymentName.length === 0 ||
                        addEmploymentStart === null ||
                        addEmploymentEnd === null
                          ? 0.5
                          : 1,
                      cursor:
                        addEmploymentName.length === 0 ||
                        addEmploymentStart === null ||
                        addEmploymentEnd === null
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={() => {
                      onCreateEmployment();
                    }}
                  >
                    <p>Ajouter</p>
                  </div>
                </div>
              </div>
              {/* LISTE DES EMPLOIS */}
              <EmploisList>
                <div className="emplois-list">
                  {agent?.employments?.length === 0 && <p>Aucun emploi.</p>}
                  {agent?.employments?.map((employment: any, index: any) => (
                    <div className="header" key={index}>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 16,
                          }}
                        >
                          {/* name */}
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label>Emploi référent</label>
                            <select
                              value={employment.name ? employment.name : ""}
                              onChange={(e) => {
                                const myNextList = [
                                  ...(agent?.employments || []),
                                ];
                                let finalJobId = 0;

                                referensJobs.some(function (el) {
                                  if (el?.jobTitle === employment.name) {
                                    //@ts-ignore
                                    finalJobId = el.id;
                                  }
                                });

                                myNextList[index] = {
                                  ...myNextList[index],
                                  name: e.target.value,
                                  referensJobId: finalJobId,
                                };
                                setAgent({
                                  ...agent,
                                  employments: myNextList,
                                });
                              }}
                            >
                              {referensJobs &&
                                referensJobs.map((job) => {
                                  return (
                                    <option
                                      value={job?.jobTitle}
                                      key={job?.jobTitle}
                                    >
                                      {job?.jobTitle}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          {/* start date */}
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label>Date de début</label>
                            <div>
                              <input
                                type="date"
                                value={
                                  employment.startDate
                                    ? changeDateFormatYYYYMMDD(
                                        employment.startDate
                                      ) || ""
                                    : ""
                                }
                                onChange={(e) => {
                                  const myNextList = [
                                    ...(agent?.employments || []),
                                  ];
                                  myNextList[index] = {
                                    ...myNextList[index],
                                    startDate: new Date(e.target.value),
                                  };
                                  setAgent({
                                    ...agent,
                                    employments: myNextList,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* end date */}
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label>Date de fin</label>
                            <div>
                              <input
                                type="date"
                                value={
                                  employment.endDate
                                    ? changeDateFormatYYYYMMDD(
                                        employment.endDate
                                      ) || ""
                                    : ""
                                }
                                onChange={(e) => {
                                  const myNextList = [
                                    ...(agent?.employments || []),
                                  ];
                                  myNextList[index] = {
                                    ...myNextList[index],
                                    endDate: new Date(e.target.value),
                                  };
                                  setAgent({
                                    ...agent,
                                    employments: myNextList,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 16,
                          }}
                        >
                          {/* quotite */}
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label>Quotité</label>
                            <div className="input">
                              <input
                                type="number"
                                value={
                                  employment.quotite ? employment.quotite : ""
                                }
                                min={0}
                                onChange={(e) => {
                                  const myNextList = [
                                    ...(agent?.employments || []),
                                  ];
                                  myNextList[index] = {
                                    ...myNextList[index],
                                    quotite: +e.target.value,
                                  };
                                  setAgent({
                                    ...agent,
                                    employments: myNextList,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          {/* type */}
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label>Type</label>
                            <div className="input">
                              <input
                                type="text"
                                value={employment.type ? employment.type : ""}
                                onChange={(e) => {
                                  const myNextList = [
                                    ...(agent?.employments || []),
                                  ];
                                  myNextList[index] = {
                                    ...myNextList[index],
                                    type: e.target.value,
                                  };
                                  setAgent({
                                    ...agent,
                                    employments: myNextList,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* status */}
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label>Statut</label>
                            <div className="input">
                              <input
                                type="text"
                                value={
                                  employment.status ? employment.status : ""
                                }
                                onChange={(e) => {
                                  const myNextList = [
                                    ...(agent?.employments || []),
                                  ];
                                  myNextList[index] = {
                                    ...myNextList[index],
                                    status: e.target.value,
                                  };
                                  setAgent({
                                    ...agent,
                                    employments: myNextList,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 16,
                          marginTop: 16,
                          marginBottom: 16,
                        }}
                      >
                        {/* BAP */}
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label>BAP Poste Occupé</label>
                          <div className="input">
                            <input
                              type="text"
                              value={
                                employment.BAPPosteOccupe
                                  ? employment.BAPPosteOccupe
                                  : ""
                              }
                              onChange={(e) => {
                                const myNextList = [
                                  ...(agent?.employments || []),
                                ];
                                myNextList[index] = {
                                  ...myNextList[index],
                                  BAPPosteOccupe: e.target.value,
                                };
                                setAgent({
                                  ...agent,
                                  employments: myNextList,
                                });
                              }}
                            />
                          </div>
                        </div>
                        {/* CatPostOccupe */}
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label>Cat Poste Occupé</label>
                          <div className="input">
                            <input
                              type="text"
                              value={
                                employment.catPostOccupe
                                  ? employment.catPostOccupe
                                  : ""
                              }
                              onChange={(e) => {
                                const myNextList = [
                                  ...(agent?.employments || []),
                                ];
                                myNextList[index] = {
                                  ...myNextList[index],
                                  catPostOccupe: e.target.value,
                                };
                                setAgent({
                                  ...agent,
                                  employments: myNextList,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* delete button */}
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {index === 0 && <label>Action</label>}
                        <div
                          className="delete-button"
                          onClick={() => {
                            onDeleteEmployment(index);
                          }}
                        >
                          <p>Supprimer</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </EmploisList>
            </SectionEmplois>
          )}
          {sectionSelected === "diplomes" && (
            <SectionFormations>
              {/* <p>Ici faire une liste pour remplir la table formations</p> */}
              {/* <p>{JSON.stringify(agent?.formations)}</p> */}
              <div className="formations-header">
                <h3>Formations de l’agent</h3>
                <div
                  className="formations-header-actions"
                  style={{
                    borderBottom: !onAddFormation
                      ? `1px solid ${COLOR_TEXT}`
                      : "0px",
                  }}
                  onClick={() => {
                    _setOnAddFormation(!onAddFormation);
                  }}
                >
                  {!onAddFormation ? (
                    <IoIosAdd size={24} color={COLOR_TEXT} />
                  ) : (
                    <IoIosRemove size={24} color={COLOR_TEXT} />
                  )}

                  <p>Ajouter une formation</p>
                </div>
              </div>

              <div
                className="header-create"
                style={{ display: onAddFormation ? "flex" : "none" }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label>Nom</label>
                  <div className="input">
                    <input
                      type="text"
                      value={addFormationName ? addFormationName : ""}
                      onChange={(e) => {
                        _setAddFormationName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label>Année</label>
                  <div className="input">
                    <input
                      type="number"
                      min={0}
                      value={addFormationYear ? addFormationYear : ""}
                      onChange={(e) => {
                        _setAddFormationYear(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="action-save"
                    style={{
                      opacity:
                        addFormationName.length === 0 ||
                        addFormationYear.length === 0
                          ? 0.5
                          : 1,
                      cursor:
                        addFormationName.length === 0 ||
                        addFormationYear.length === 0
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={() => {
                      onCreateFormation();
                    }}
                  >
                    <p>Ajouter</p>
                  </div>
                </div>
              </div>
              {/* LISTE DES FORMATIONS */}
              <FormationsList>
                <div className="formations-list">
                  {agent?.formations?.length === 0 && <p>Aucune formation.</p>}
                  {agent?.formations?.map((formation: any, index: any) => (
                    <div className="header" key={index}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {index === 0 && <label>Nom de la formation</label>}

                        <div className="input">
                          <input
                            type="text"
                            value={formation.name}
                            onChange={(e) => {
                              const myNextList = [...(agent?.formations || [])];
                              myNextList[index] = {
                                ...myNextList[index],
                                name: e.target.value,
                              };
                              setAgent({
                                ...agent,
                                formations: myNextList,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {index === 0 && <label>Année de la formation</label>}
                        <div className="input">
                          <input
                            type="number"
                            min={0}
                            value={formation.year}
                            onChange={(e) => {
                              const myNextList = [...(agent?.formations || [])];
                              myNextList[index] = {
                                ...myNextList[index],
                                year: +e.target.value,
                              };
                              setAgent({
                                ...agent,
                                formations: myNextList,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {index === 0 && <label>Action</label>}

                        <div
                          className="delete-button"
                          onClick={() => {
                            onDeleteFormation(index);
                          }}
                        >
                          <p>Supprimer</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </FormationsList>
            </SectionFormations>
          )}
        </RightContent>
      </Content>
    </BodyContainer>
  );
};

export default EditAgent;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const Header = styled.div`
  padding: 32px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div.action-save {
    display: flex;
    height: 56px;
    padding: 16px 32px;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #57b7e6;
    box-shadow: 0px 8px 24px 0px rgba(51, 51, 51, 0.08);
    color: white;
    cursor: pointer;

    p {
      color: white;
    }
  }
`;

const Content = styled.div`
  overflow: auto;
  padding: 8px 40px;
  flex: 1;
  display: flex;
  gap: 40px;
`;

const LeftContent = styled.div`
  width: 280px;
  padding: 40px 32px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(51, 51, 51, 0.08);

  > .header-of-left {
    padding-bottom: 32px;
    border-bottom: 1px solid #333;
    margin-bottom: 32px;

    > div {
      p {
        font-size: 16px;
        font-weight: bold;
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  > .actions-left {
    p {
      margin-bottom: 8px;

      &.acronym {
        margin-bottom: 32px;
      }
    }

    > div {
      display: flex;
      border-radius: 8px;
      align-items: center;
      padding: 16px;
      gap: 8px;
      cursor: pointer;
      border: 1px solid #333;
      margin-bottom: 8px;

      &.selected {
        background-color: rgba(87, 183, 230, 0.32);
        border-color: transparent;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
`;

const RightContent = styled.div`
  flex: 1;
  padding-top: 35px;

  .delete-button {
    display: flex;
    height: 56px;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: ${COLOR_WHITE};
    /* box-shadow: 0px 8px 24px 0px rgba(51, 51, 51, 0.08); */
    cursor: pointer;

    p {
      color: ${COLOR_TEXT};
    }
  }

  label {
    display: block;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: 700;
    color: #1d2769;
    margin-bottom: 11px;
  }

  div.input {
    position: relative;
    margin-bottom: 32px;

    &:before {
      content: " ";
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url("/icons/edit.svg") no-repeat;
    }
  }

  div.textarea {
    position: relative;
    margin-bottom: 32px;

    &:before {
      content: " ";
      position: absolute;
      top: 16px;
      right: 12px;
      width: 24px;
      height: 24px;
      background: url("/icons/edit.svg") no-repeat;
    }
  }

  input[type="text"] {
    height: 56px;
    padding: 0px 12px;
    border-radius: 8px;
    border: 1px solid #e6eff5;
    background: #fff;
    width: 100%;
    padding-right: 40px;
  }

  input[type="number"] {
    height: 56px;
    padding: 0px 12px;
    border-radius: 8px;
    border: 1px solid #e6eff5;
    background: #fff;
    width: 100%;
    padding-right: 40px;
  }

  input[type="date"] {
    height: 56px;
    padding: 0px 12px;
    border-radius: 8px;
    border: 1px solid #e6eff5;
    background: #fff;
    width: 100%;
    margin-bottom: 32px;
  }

  textarea {
    min-height: 200px;
    padding: 16px 12px;
    border-radius: 8px;
    border: 1px solid #e6eff5;
    background: #fff;
    width: 100%;
  }

  select {
    height: 56px;
    padding: 0px 12px;
    border-radius: 8px;
    border: 1px solid #e6eff5;
    background: #fff;
    width: 100%;
  }
`;

const SectionEdit = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;

  .telework-days {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 9px;

    > p {
      height: 82.5px;
      line-height: 82.5px;
      text-align: center;
      border-radius: 8px;
      border: 1px solid #e6eff5;
      background: #fff;
      cursor: pointer;

      &.selected {
        background: rgba(29, 39, 105, 0.16);
        border-color: #1d2769;
      }
    }
  }
`;

const SectionEmplois = styled.div`
  .emplois-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 40px; */
    font-family: "Ubuntu", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.58px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: ${COLOR_TITLE};
  }

  .header-create {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    gap: 8px;
    width: 100%;
    border: 1px solid ${COLOR_TEXT};
    border-radius: 8px 0px 8px 8px;
    padding: 16px;

    select {
      margin-bottom: 0px;
    }

    div > .input {
      margin-bottom: 0px;
    }

    div > input {
      margin-bottom: 0px;
    }

    div.action-save {
      display: flex;
      height: 56px;
      padding: 16px 32px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #57b7e6;
      box-shadow: 0px 8px 24px 0px rgba(51, 51, 51, 0.08);
      color: white;
      cursor: pointer;

      p {
        color: white;
      }
    }
  }

  .emplois-header-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    user-select: none;
    padding-right: 8px;
    border-radius: 0px;
    /* border-bottom: 1px solid ${COLOR_TEXT}; */
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-left: 1px solid rgba(0, 0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    :hover {
      border-color: ${COLOR_TITLE};
      border-radius: 4px;
      background-color: ${COLOR_WHITE};
    }
  }
`;

const SectionFormations = styled.div`
  .formations-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 40px; */
    font-family: "Ubuntu", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.58px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: ${COLOR_TITLE};
  }

  .header-create {
    text-align: left;
    gap: 8px;
    width: 100%;
    border: 1px solid ${COLOR_TEXT};
    border-radius: 8px 0px 8px 8px;
    padding: 16px;

    div > .input {
      margin-bottom: 0px;
    }

    div.action-save {
      display: flex;
      height: 56px;
      padding: 16px 32px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #57b7e6;
      box-shadow: 0px 8px 24px 0px rgba(51, 51, 51, 0.08);
      color: white;
      cursor: pointer;

      p {
        color: white;
      }
    }
  }

  .formations-header-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    user-select: none;
    align-items: center;
    padding: 4px;
    padding-right: 8px;
    border-radius: 0px;
    border-bottom: 1px solid ${COLOR_TEXT};
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-left: 1px solid rgba(0, 0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    :hover {
      border-color: ${COLOR_TITLE};
      border-radius: 4px;
      background-color: ${COLOR_WHITE};
    }
  }
`;

const FormationsList = styled.div`
  margin-top: 40px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    gap: 8px;
    width: 100%;
  }

  .formations-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .input {
      margin-bottom: 0px;
    }

    section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      gap: 8px;
      width: 100%;
    }
  }
`;

const EmploisList = styled.div`
  margin-top: 40px;

  .header {
    text-align: left;
    gap: 8px;
    width: 100%;
    border: 1px solid ${COLOR_TEXT};
    border-radius: 8px 0px 8px 8px;
    padding: 16px;
  }

  .emplois-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .input {
      margin-bottom: 0px;
    }

    input {
      margin-bottom: 0px;
      width: 100%;
    }

    select {
      margin-bottom: 0px;
    }

    section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      gap: 8px;
      width: 100%;
    }
  }
`;
