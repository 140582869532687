import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import { COLOR_TITLE } from "../../constants/cts_colors";
import BodyContainer from "../../components/BodyContainer";
import { MdCloudUpload } from "react-icons/md";
import ImportEmploymentForm from "./components/importEmploymentsForm";
import Modal from "../../components/Modal";
import ToastAlert from "../../components/ToastAlert";
import { IReferensJobInfos } from "../../interfaces/referensJob";
import { useReferensJobs } from "../../common/contexts/referensJobContext";
import ReferensJobViewer from "./components/referensJobViewer";
import EditReferensJobForm from "./components/editReferensJobForm";
import LoadingContainer from "../../components/LoadingContainer";

const ReferensJobs = () => {
  const { user } = useAuthentication();

  const [isLoading, _setIsLoading] = useState<boolean>(false);

  const [selected, _setSelected] = useState<number>(0);
  const [isImportingEmploymentModalOpen, _setIsImportingEmploymentModalOpen] =
    useState<boolean>(false);

  const [isEditingReferensJobModalOpen, _setIsEditingReferensJobModalOpen] =
    useState<boolean>(false);

  const [editCurrentReferensJobId, _setEditCurrentReferensJobId] =
    useState<number>();
  const [editCurrentReferensJobCode, _setEditCurrentReferensJobCode] =
    useState<string>("");

  const { onGetAllReferensJobs } = useReferensJobs();

  // toast
  const [toastVisible, _setToastVisible] = useState<boolean>(false);
  const [toastMessage, _setToastMessage] = useState<string>("");

  const [allReferensJobs, _setAllReferensJobs] = useState<
    Array<IReferensJobInfos>
  >([]);

  const [headArray, _setHeadArray] = useState<Array<string>>([]);

  const [selectedContent, _setSelectedContent] = useState<number>(-1);

  // set the title of the page
  useEffect(() => {
    document.title = "Accueil | Sorbonne";

    _setIsLoading(true);

    onGetAllReferensJobs().then((response: Array<IReferensJobInfos>) => {
      _setAllReferensJobs(response);

      let array: Array<string> = [];

      response.map((job: IReferensJobInfos) => {
        if (!array.includes(job.branchCode)) array.push(job.branchCode);
      });

      _setHeadArray(array.sort());
      _setIsLoading(false);
    });
  }, []);

  return (
    <BodyContainer title="Annuaire d'emplois">
      {/* HEAD */}
      <HeadDiv>
        {/* ALL IMPORTED JOBS DIVIDED BY BRANCH CODE */}
        <div>
          {headArray &&
            headArray.length !== 0 &&
            headArray.map((head, key) => {
              return (
                <Head
                  key={key}
                  className={
                    selected === key && !isImportingEmploymentModalOpen
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    _setSelected(key);
                    _setIsImportingEmploymentModalOpen(false);
                  }}
                >
                  <h1>{head}</h1>
                </Head>
              );
            })}
        </div>
        {/* IMPORT */}
        {user && user.role === 1 && allReferensJobs.length === 0 && (
          <Head
            className={isImportingEmploymentModalOpen ? "active" : ""}
            onClick={() => {
              _setIsImportingEmploymentModalOpen(true);
            }}
          >
            <MdCloudUpload
              className="icon"
              color={isImportingEmploymentModalOpen ? "white" : COLOR_TITLE}
            />
          </Head>
        )}
      </HeadDiv>

      {/* CONTENT */}
      <HeadContent
        style={{
          borderTopRightRadius: user && user.role === 0 ? 10 : 0,
        }}
      >
        {headArray &&
          headArray.length !== 0 &&
          headArray.map((head, key) => {
            let contentJobs: Array<IReferensJobInfos> = [];
            allReferensJobs.map((job) => {
              if (job.branchCode === head) contentJobs.push(job);
            });
            return (
              <Content key={key} className={selected === key ? "active" : ""}>
                {contentJobs &&
                  contentJobs.map((contentJob, contentKey) => {
                    return (
                      <ReferensJobViewer
                        key={contentKey}
                        contentJob={contentJob}
                        contentKey={contentKey}
                        selectedId={selectedContent}
                        onDisplay={() => {
                          if (contentKey !== selectedContent)
                            _setSelectedContent(contentKey);
                          else _setSelectedContent(-1);
                        }}
                        isAdmin={user.role === 1}
                        onEditReferensJob={() => {
                          _setIsEditingReferensJobModalOpen(true);
                          _setEditCurrentReferensJobCode(contentJob.codeJob);
                          _setEditCurrentReferensJobId(contentJob.id);
                        }}
                      />
                    );
                  })}
              </Content>
            );
          })}
      </HeadContent>

      <NoContent
        style={{
          display: allReferensJobs.length === 0 ? "flex" : "none",
        }}
      >
        <div style={{ display: isLoading ? "flex" : "none" }}>
          <LoadingContainer />
        </div>
        <div style={{ display: isLoading ? "none" : "flex" }}>
          <b style={{ textAlign: "center", marginBottom: 12 }}>
            Aucun emplois référens.
          </b>
          {user && user.role === 1 && (
            <p style={{ textAlign: "center" }}>
              Vous pouvez en importer en cliquant en haut a droite
            </p>
          )}
        </div>
      </NoContent>

      {/* edit description for referens job */}
      {isEditingReferensJobModalOpen && editCurrentReferensJobId && (
        <Modal onClose={() => _setIsEditingReferensJobModalOpen(false)}>
          <EditReferensJobForm
            title={`Description de l'emploi ${editCurrentReferensJobCode}`}
            actionString="Mettre à jour"
            idReferensJob={editCurrentReferensJobId}
            onReferensJobUpdated={() => {
              _setIsEditingReferensJobModalOpen(false);
              _setToastMessage(
                "Mise à jour de l'emploi effectuée avec succès !"
              );
              _setToastVisible(true);
            }}
          />
        </Modal>
      )}

      {/* import agent modal */}
      {isImportingEmploymentModalOpen && (
        <Modal onClose={() => _setIsImportingEmploymentModalOpen(false)}>
          <ImportEmploymentForm
            title="Importer des emplois"
            actionString="Importer"
            onEmploymentImported={() => {
              _setIsImportingEmploymentModalOpen(false);
              _setToastMessage(
                "Importation des emplois effectuée avec succès !"
              );
              _setIsLoading(true);
              _setToastVisible(true);
              onGetAllReferensJobs().then(
                (response: Array<IReferensJobInfos>) => {
                  _setAllReferensJobs(response);

                  let array: Array<string> = [];

                  response.map((job: IReferensJobInfos) => {
                    if (!array.includes(job.branchCode))
                      array.push(job.branchCode);
                  });

                  _setHeadArray(array.sort());
                }
              );
            }}
          />
        </Modal>
      )}

      {/* TOAST */}
      {toastVisible && toastMessage && (
        <ToastAlert
          text={toastMessage}
          handleOk={() => {
            _setToastVisible(false);
          }}
          endedTimer={() => {
            _setToastVisible(false);
          }}
        />
      )}
    </BodyContainer>
  );
};

export default ReferensJobs;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const HeadDiv = styled.div`
  display: flex;
  max-height: 62px;
  flex-direction: row;
  justify-content: space-between;

  div {
    display: flex;
  }
`;

const Head = styled.button`
  z-index: 1;
  cursor: pointer;
  margin-top: 12px;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: none;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-out;

  .icon {
    width: 20px;
    height: 20px;
  }

  &.active {
    margin-top: 4px;
    background-color: rgba(252, 97, 97, 0.95);
    h1 {
      color: white;
    }
  }

  &:not(.active):hover {
    margin-top: 8px;
    background-color: rgba(245, 245, 245, 0.95);
  }

  h1 {
    font-weight: bold;
    font-size: 1.5em;
    color: ${COLOR_TITLE};
  }
`;

const HeadContent = styled.div`
  z-index: 2;
  width: 100%;
  height: auto;

  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const Content = styled.div`
  &.active {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  padding: 20px;

  display: none;
`;

const NoContent = styled.div`
  width: 100%;
  height: 93%;
  padding: 12px;

  div {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
